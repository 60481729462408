import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { Button, Typography } from '@mui/material'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import Input from '../../../../../../ui/input/index.tsx'
import AddImage from './AddImage.jsx'

import useGenerateProtocolDataMi1592 from '../../../../../hooks/useGenerateProtocolDataMi1592.js'
import { setShow } from '../../../../../../store/slices/app/comps/feedback.js'
import { changeProperty } from '../state.js'

import R from '../../../../../../services/app/client-server/request.service.js'

import {
  Wrapper,
  Container,
  LabelFrame25,
  LabelFrame50,
  CountIndicationBox,
  QActualBox,
  FlexStartFrame,
} from '../style.ts'

const Indications = () => {
  const dispatch = useDispatch()
  const [tariffIds, setTariffIds] = useState([])

  const { generateValuesStartFinish, defaultValuesStartFinish, generateQactual } =
    useGenerateProtocolDataMi1592()

  const [startEndDataUpdate, setStartEndDataUpdate] = useState(false)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const diameter = useSelector(state => state.gost8101222Reducer.diameter)
  const q_min = useSelector(state => state.gost8101222Reducer.q_min)
  const q_t = useSelector(state => state.gost8101222Reducer.q_t)
  const q_max = useSelector(state => state.gost8101222Reducer.q_max)
  const q_actual = useSelector(state => state.gost8101222Reducer.q_actual)

  const q_min_limit = useSelector(state => state.gost8101222Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.gost8101222Reducer.q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const q_i_1 = useSelector(state => state.gost8101222Reducer.q_i_1)
  const q_i_2 = useSelector(state => state.gost8101222Reducer.q_i_2)
  const q_i_3 = useSelector(state => state.gost8101222Reducer.q_i_3)
  const q_i_4 = useSelector(state => state.gost8101222Reducer.q_i_4)
  const q_i_5 = useSelector(state => state.gost8101222Reducer.q_i_5)
  const q_i_6 = useSelector(state => state.gost8101222Reducer.q_i_6)
  const q_i_7 = useSelector(state => state.gost8101222Reducer.q_i_7)
  const q_i_8 = useSelector(state => state.gost8101222Reducer.q_i_8)
  const q_i_9 = useSelector(state => state.gost8101222Reducer.q_i_9)

  const start_1 = useSelector(state => state.gost8101222Reducer.start_1)
  const start_2 = useSelector(state => state.gost8101222Reducer.start_2)
  const start_3 = useSelector(state => state.gost8101222Reducer.start_3)
  const start_4 = useSelector(state => state.gost8101222Reducer.start_4)
  const start_5 = useSelector(state => state.gost8101222Reducer.start_5)
  const start_6 = useSelector(state => state.gost8101222Reducer.start_6)
  const start_7 = useSelector(state => state.gost8101222Reducer.start_7)
  const start_8 = useSelector(state => state.gost8101222Reducer.start_8)
  const start_9 = useSelector(state => state.gost8101222Reducer.start_9)

  const finish_1 = useSelector(state => state.gost8101222Reducer.finish_1)
  const finish_2 = useSelector(state => state.gost8101222Reducer.finish_2)
  const finish_3 = useSelector(state => state.gost8101222Reducer.finish_3)
  const finish_4 = useSelector(state => state.gost8101222Reducer.finish_4)
  const finish_5 = useSelector(state => state.gost8101222Reducer.finish_5)
  const finish_6 = useSelector(state => state.gost8101222Reducer.finish_6)
  const finish_7 = useSelector(state => state.gost8101222Reducer.finish_7)
  const finish_8 = useSelector(state => state.gost8101222Reducer.finish_8)
  const finish_9 = useSelector(state => state.gost8101222Reducer.finish_9)

  const v_y_i_1 = useSelector(state => state.gost8101222Reducer.v_y_i_1)
  const v_y_i_2 = useSelector(state => state.gost8101222Reducer.v_y_i_2)
  const v_y_i_3 = useSelector(state => state.gost8101222Reducer.v_y_i_3)
  const v_y_i_4 = useSelector(state => state.gost8101222Reducer.v_y_i_4)
  const v_y_i_5 = useSelector(state => state.gost8101222Reducer.v_y_i_5)
  const v_y_i_6 = useSelector(state => state.gost8101222Reducer.v_y_i_6)
  const v_y_i_7 = useSelector(state => state.gost8101222Reducer.v_y_i_7)
  const v_y_i_8 = useSelector(state => state.gost8101222Reducer.v_y_i_8)
  const v_y_i_9 = useSelector(state => state.gost8101222Reducer.v_y_i_9)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_actualChange = param => {
    const value = param.replace(',', '.')
    dispatch(changeProperty({ value: 'q_actual', label: value }))
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_1Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_1',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_1Change = param => {
    dispatch(
      changeProperty({
        value: 'start_1',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_1Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_1',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_1Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_1',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_1 = (parseFloat(finish_1) - parseFloat(start_1)).toFixed(4)
  const permissible_error_1 = (
    ((parseFloat(v_u_i_1) - parseFloat(v_y_i_1)) / parseFloat(v_y_i_1)) *
    100
  ).toFixed(2)

  const indicationPass_1 =
    permissible_error_1 >= -parseFloat(q_min_limit) &&
    permissible_error_1 <= parseFloat(q_min_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_2Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_2',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_2Change = param => {
    dispatch(
      changeProperty({
        value: 'start_2',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_2Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_2',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_2Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_2',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_2 = (parseFloat(finish_2) - parseFloat(start_2)).toFixed(4)

  const permissible_error_2 = (
    ((parseFloat(v_u_i_2) - parseFloat(v_y_i_2)) / parseFloat(v_y_i_2)) *
    100
  ).toFixed(2)

  const indicationPass_2 =
    permissible_error_2 >= -parseFloat(q_min_limit) &&
    permissible_error_2 <= parseFloat(q_min_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_3Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_3',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_3Change = param => {
    dispatch(
      changeProperty({
        value: 'start_3',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_3Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_3',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_3Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_3',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_3 = (parseFloat(finish_3) - parseFloat(start_3)).toFixed(4)

  const permissible_error_3 = (
    ((parseFloat(v_u_i_3) - parseFloat(v_y_i_3)) / parseFloat(v_y_i_3)) *
    100
  ).toFixed(2)

  const indicationPass_3 =
    permissible_error_3 >= -parseFloat(q_min_limit) &&
    permissible_error_3 <= parseFloat(q_min_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_4Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_4',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_4Change = param => {
    dispatch(
      changeProperty({
        value: 'start_4',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_4Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_4',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_4Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_4',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_4 = (parseFloat(finish_4) - parseFloat(start_4)).toFixed(4)

  const permissible_error_4 = (
    ((parseFloat(v_u_i_4) - parseFloat(v_y_i_4)) / parseFloat(v_y_i_4)) *
    100
  ).toFixed(2)

  const indicationPass_4 =
    permissible_error_4 >= -parseFloat(q_max_limit) &&
    permissible_error_4 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_5Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_5',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_5Change = param => {
    dispatch(
      changeProperty({
        value: 'start_5',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_5Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_5',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_5Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_5',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_5 = (parseFloat(finish_5) - parseFloat(start_5)).toFixed(4)

  const permissible_error_5 = (
    ((parseFloat(v_u_i_5) - parseFloat(v_y_i_5)) / parseFloat(v_y_i_5)) *
    100
  ).toFixed(2)

  const indicationPass_5 =
    permissible_error_5 >= -parseFloat(q_max_limit) &&
    permissible_error_5 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_6Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_6',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_6Change = param => {
    dispatch(
      changeProperty({
        value: 'start_6',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_6Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_6',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_6Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_6',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_6 = (parseFloat(finish_6) - parseFloat(start_6)).toFixed(4)

  const permissible_error_6 = (
    ((parseFloat(v_u_i_6) - parseFloat(v_y_i_6)) / parseFloat(v_y_i_6)) *
    100
  ).toFixed(2)

  const indicationPass_6 =
    permissible_error_6 >= -parseFloat(q_max_limit) &&
    permissible_error_6 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_7Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_7',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_7Change = param => {
    dispatch(
      changeProperty({
        value: 'start_7',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_7Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_7',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_7Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_7',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_7 = (parseFloat(finish_7) - parseFloat(start_7)).toFixed(4)

  const permissible_error_7 = (
    ((parseFloat(v_u_i_7) - parseFloat(v_y_i_7)) / parseFloat(v_y_i_7)) *
    100
  ).toFixed(2)

  const indicationPass_7 =
    permissible_error_7 >= -parseFloat(q_max_limit) &&
    permissible_error_7 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_8Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_8',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_8Change = param => {
    dispatch(
      changeProperty({
        value: 'start_8',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_8Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_8',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_8Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_8',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_8 = (parseFloat(finish_8) - parseFloat(start_8)).toFixed(4)

  const permissible_error_8 = (
    ((parseFloat(v_u_i_8) - parseFloat(v_y_i_8)) / parseFloat(v_y_i_8)) *
    100
  ).toFixed(2)

  const indicationPass_8 =
    permissible_error_8 >= -parseFloat(q_max_limit) &&
    permissible_error_8 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_9Change = param => {
    dispatch(
      changeProperty({
        value: 'q_i_9',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleStart_9Change = param => {
    dispatch(
      changeProperty({
        value: 'start_9',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleFinish_9Change = param => {
    dispatch(
      changeProperty({
        value: 'finish_9',
        label: param.replace(',', '.'),
      }),
    )
  }

  const handleV_y_i_9Change = param => {
    dispatch(
      changeProperty({
        value: 'v_y_i_9',
        label: param.replace(',', '.'),
      }),
    )
  }

  const v_u_i_9 = (parseFloat(finish_9) - parseFloat(start_9)).toFixed(4)

  const permissible_error_9 = (
    ((parseFloat(v_u_i_9) - parseFloat(v_y_i_9)) / parseFloat(v_y_i_9)) *
    100
  ).toFixed(2)

  const indicationPass_9 =
    permissible_error_9 >= -parseFloat(q_max_limit) &&
    permissible_error_9 <= parseFloat(q_max_limit)

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indicationsPass',
        label: [
          indicationPass_1,
          indicationPass_2,
          indicationPass_3,
          indicationPass_4,
          indicationPass_5,
          indicationPass_6,
          indicationPass_7,
          indicationPass_8,
          indicationPass_9,
        ],
      }),
    )
  }, [
    indicationPass_1,
    indicationPass_2,
    indicationPass_3,
    indicationPass_4,
    indicationPass_5,
    indicationPass_6,
    indicationPass_7,
    indicationPass_8,
    indicationPass_9,
    dispatch,
  ])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indications',
        label: [
          {
            q_i_1: q_i_1,
            q_i_2: q_i_2,
            q_i_3: q_i_3,
            start_1: start_1,
            start_2: start_2,
            start_3: start_3,
            finish_1: finish_1,
            finish_2: finish_2,
            finish_3: finish_3,
            v_y_i_1: v_y_i_1,
            v_y_i_2: v_y_i_2,
            v_y_i_3: v_y_i_3,
            v_u_i_1: v_u_i_1,
            v_u_i_2: v_u_i_2,
            v_u_i_3: v_u_i_3,
            permissible_error_1: permissible_error_1,
            permissible_error_2: permissible_error_2,
            permissible_error_3: permissible_error_3,
          },
          {
            q_i_1: q_i_4,
            q_i_2: q_i_5,
            q_i_3: q_i_6,
            start_1: start_4,
            start_2: start_5,
            start_3: start_6,
            finish_1: finish_4,
            finish_2: finish_5,
            finish_3: finish_6,
            v_y_i_1: v_y_i_4,
            v_y_i_2: v_y_i_5,
            v_y_i_3: v_y_i_6,
            v_u_i_1: v_u_i_4,
            v_u_i_2: v_u_i_5,
            v_u_i_3: v_u_i_6,
            permissible_error_1: permissible_error_4,
            permissible_error_2: permissible_error_5,
            permissible_error_3: permissible_error_6,
          },
          {
            q_i_1: q_i_7,
            q_i_2: q_i_8,
            q_i_3: q_i_9,
            start_1: start_7,
            start_2: start_8,
            start_3: start_9,
            finish_1: finish_7,
            finish_2: finish_8,
            finish_3: finish_9,
            v_y_i_1: v_y_i_7,
            v_y_i_2: v_y_i_8,
            v_y_i_3: v_y_i_9,
            v_u_i_1: v_u_i_7,
            v_u_i_2: v_u_i_8,
            v_u_i_3: v_u_i_9,
            permissible_error_1: permissible_error_7,
            permissible_error_2: permissible_error_8,
            permissible_error_3: permissible_error_9,
          },
        ],
      }),
    )
  }, [
    q_i_1,
    q_i_2,
    q_i_3,
    q_i_4,
    q_i_5,
    q_i_6,
    q_i_7,
    q_i_8,
    q_i_9,
    start_1,
    start_2,
    start_3,
    start_4,
    start_5,
    start_6,
    start_7,
    start_8,
    start_9,
    finish_1,
    finish_2,
    finish_3,
    finish_4,
    finish_5,
    finish_6,
    finish_7,
    finish_8,
    finish_9,
    v_y_i_1,
    v_y_i_2,
    v_y_i_3,
    v_y_i_4,
    v_y_i_5,
    v_y_i_6,
    v_y_i_7,
    v_y_i_8,
    v_y_i_9,
    v_u_i_1,
    v_u_i_2,
    v_u_i_3,
    v_u_i_4,
    v_u_i_5,
    v_u_i_6,
    v_u_i_7,
    v_u_i_8,
    v_u_i_9,
    permissible_error_1,
    permissible_error_2,
    permissible_error_3,
    permissible_error_4,
    permissible_error_5,
    permissible_error_6,
    permissible_error_7,
    permissible_error_8,
    permissible_error_9,
    dispatch,
  ])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  const titleContent = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
      <Typography variant='subtitle2'>
        Согласно пункту № 4.3.1 Методика поверки «Рг» ГОСТ 8.1012-2022 (с изменением №1), при
        проведении поверки осуществляют цифровую фиксацию (ЦФ) поверки. В рамках ЦФ происходит
        фотофиксация:
      </Typography>
      <Typography variant='subtitle2'>
        1. <span style={{ fontWeight: 'bold' }}>Общего вида поверяемого счетчика</span> воды или
        <span style={{ fontWeight: 'bold' }}>индикатора</span> счетчика воды
        <span style={{ fontWeight: 'bold' }}>после</span> каждого измерения, осуществляемого в
        соответствии с <span style={{ fontWeight: 'bold' }}>подпунктом 4.3.7.4.</span> при съеме
        показаний <span style={{ fontWeight: 'bold' }}>визуально</span>. Данная фотофиксация должна
        отображать <span style={{ fontWeight: 'bold' }}>значения накопленного объема счетчика</span>
        воды.
      </Typography>
      <Typography variant='subtitle2'>
        2.
        <span span style={{ fontWeight: 'bold' }}>
          Общего вида индикатора эталона после каждого измерения
        </span>
        , осуществляемого в соответствии с
        <span span style={{ fontWeight: 'bold' }}>
          подпунктом 4.3.7.4.
        </span>
        Данная фотофиксации должны отображать
        <span span style={{ fontWeight: 'bold' }}>
          значения накопленного объема эталона
        </span>
        во время измерения.
      </Typography>
    </div>
  )

  return (
    <Wrapper>
      <FlexStartFrame>
        <Typography variant='h2'>Определение относительной погрешности</Typography>

        {tariffIds && tariffIds.includes(5) && (
          <LabelFrame50>
            <Container>
              <LabelFrame50>
                {!startEndDataUpdate && (
                  <Button
                    disabled={
                      (start_1 || start_1 === 0) && q_min && q_t && q_min_limit && diameter
                        ? false
                        : true
                    }
                    onMouseDown={() => {
                      generateQactual()
                    }}
                    onClick={() => {
                      setStartEndDataUpdate(true)
                      dispatch(setShow(true))

                      setTimeout(() => {
                        generateValuesStartFinish()
                        setStartEndDataUpdate(false)
                        dispatch(setShow(false))
                      }, 1100)
                    }}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '10px 20px 12px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Тестовая среда
                  </Button>
                )}
                {startEndDataUpdate && (
                  <Button
                    disabled={true}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '10px 20px 12px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <CircularProgress
                        variant='determinate'
                        sx={{
                          color: theme =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant='indeterminate'
                        disableShrink
                        sx={{
                          color: theme => (theme.palette.mode === 'light' ? 'grey' : 'grey'),
                          animationDuration: '550ms',
                          position: 'absolute',
                          opacity: 0.6,
                          left: 0,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                        }}
                        size={30}
                        thickness={4}
                      />
                    </Box>
                  </Button>
                )}
              </LabelFrame50>
              <LabelFrame50>
                <Button
                  disabled={
                    (start_1 || start_1 === 0) && q_min && q_max && q_t && q_min_limit && diameter
                      ? false
                      : true
                  }
                  onClick={() => {
                    setStartEndDataUpdate(true)

                    setTimeout(() => {
                      defaultValuesStartFinish()
                      dispatch(changeProperty({ value: 'q_actual', label: '' }))
                      setStartEndDataUpdate(false)
                    }, 1100)
                  }}
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '10px 20px 12px',
                    borderRadius: '12px',
                    marginRight: '0px',
                    width: '100%',
                  }}
                >
                  Сбросить все значения
                </Button>
              </LabelFrame50>
            </Container>
            <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
              Генерация данных приведена как пример в помощь поверителю. При создании протокола все
              данные необходимо скорректировать
            </Typography>
          </LabelFrame50>
        )}
      </FlexStartFrame>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <Typography variant='subtitle1'>
          {q_min ? `Введенное значение Qmin : ${q_min}, м³/ч` : 'Введите Qmin (описание типа)'}
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>1</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_1 ? q_i_1 : ''}
                actions={{
                  change: handleQ_i_1Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_1 ? start_1 : ''}
                actions={{
                  change: handleStart_1Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_1 ? finish_1 : ''}
                actions={{
                  change: handleFinish_1Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_1 ? v_y_i_1 : ''}
                actions={{
                  change: handleV_y_i_1Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_1 && finish_1 && v_y_i_1 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_1
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_1 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_1} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_1 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_1} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_1 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_min_limit} %</span>
              </p>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>2</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_2 ? q_i_2 : ''}
                actions={{
                  change: handleQ_i_2Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_2 ? start_2 : ''}
                actions={{
                  change: handleStart_2Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_2 ? finish_2 : ''}
                actions={{
                  change: handleFinish_2Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_2 ? v_y_i_2 : ''}
                actions={{
                  change: handleV_y_i_2Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_2 && finish_2 && v_y_i_2 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_2
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_2 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_2} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_2 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_2} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_2 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_min_limit} %</span>
              </p>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>3</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_3 ? q_i_3 : ''}
                actions={{
                  change: handleQ_i_3Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_3 ? start_3 : ''}
                actions={{
                  change: handleStart_3Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_3 ? finish_3 : ''}
                actions={{
                  change: handleFinish_3Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_3 ? v_y_i_3 : ''}
                actions={{
                  change: handleV_y_i_3Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_3 && finish_3 && v_y_i_3 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_3
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_3} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_3} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_min_limit} %</span>
              </p>
            </div>
          )}
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <Typography variant='subtitle1'>
          {q_t
            ? `Значение 1.1Qп : ${(1.1 * q_t).toFixed(3)}, м³/ч`
            : 'Введите переходный расход (Qп'}
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>4</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_4 ? q_i_4 : ''}
                actions={{
                  change: handleQ_i_4Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_4 ? start_4 : ''}
                actions={{
                  change: handleStart_4Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_4 ? finish_4 : ''}
                actions={{
                  change: handleFinish_4Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_4 ? v_y_i_4 : ''}
                actions={{
                  change: handleV_y_i_4Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_4 && finish_4 && v_y_i_4 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_4
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_4 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_4} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_4 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_4} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_4 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_max_limit} %</span>
              </p>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>5</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_5 ? q_i_5 : ''}
                actions={{
                  change: handleQ_i_5Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_5 ? start_5 : ''}
                actions={{
                  change: handleStart_5Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_5 ? finish_5 : ''}
                actions={{
                  change: handleFinish_5Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_5 ? v_y_i_5 : ''}
                actions={{
                  change: handleV_y_i_5Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_5 && finish_5 && v_y_i_5 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_5
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_5 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_5} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_5 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_5} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_5 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_max_limit} %</span>
              </p>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>6</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_6 ? q_i_6 : ''}
                actions={{
                  change: handleQ_i_6Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_6 ? start_6 : ''}
                actions={{
                  change: handleStart_6Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_6 ? finish_6 : ''}
                actions={{
                  change: handleFinish_6Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_6 ? v_y_i_6 : ''}
                actions={{
                  change: handleV_y_i_6Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_6 && finish_6 && v_y_i_6 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_6
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_6 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_6} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_6 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_6} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_6 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_max_limit} %</span>
              </p>
            </div>
          )}
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <QActualBox>
          <Typography variant='subtitle1'>Введите значение Qmax (фактический) :</Typography>
          <LabelFrame50>
            <Input
              type={'text'}
              placeholder={'Qmax фактический'}
              value={q_actual}
              isDisabled={startEndDataUpdate}
              actions={{
                change: handleQ_actualChange,
              }}
              className={'backgroundColorWhite'}
            />
          </LabelFrame50>
        </QActualBox>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>7</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_7 ? q_i_7 : ''}
                actions={{
                  change: handleQ_i_7Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_7 ? start_7 : ''}
                actions={{
                  change: handleStart_7Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_7 ? finish_7 : ''}
                actions={{
                  change: handleFinish_7Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_7 ? v_y_i_7 : ''}
                actions={{
                  change: handleV_y_i_7Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_7 && finish_7 && v_y_i_7 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_7
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_7 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_7} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_7 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_7} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_7 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_max_limit} %</span>
              </p>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>8</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_8 ? q_i_8 : ''}
                actions={{
                  change: handleQ_i_8Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_8 ? start_8 : ''}
                actions={{
                  change: handleStart_8Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_8 ? finish_8 : ''}
                actions={{
                  change: handleFinish_8Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_8 ? v_y_i_8 : ''}
                actions={{
                  change: handleV_y_i_8Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_8 && finish_8 && v_y_i_8 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_8
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_8 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_8} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_8 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_8} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_8 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_max_limit} %</span>
              </p>
            </div>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Container>
            <CountIndicationBox>
              <Typography variant='subtitle1'>9</Typography>
            </CountIndicationBox>

            <AddImage title={titleContent} count={3} />

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Расход во время измерения'}
                placeholder={'0.0000'}
                value={q_i_9 ? q_i_9 : ''}
                actions={{
                  change: handleQ_i_9Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на начало'}
                placeholder={'0.0000'}
                value={start_9 ? start_9 : ''}
                actions={{
                  change: handleStart_9Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Показания на конец'}
                placeholder={'0.0000'}
                value={finish_9 ? finish_9 : ''}
                actions={{
                  change: handleFinish_9Change,
                }}
              />
            </LabelFrame25>

            <LabelFrame25>
              <Input
                isDisabled={startEndDataUpdate}
                label={'Объем, по показаниям эталона, м³'}
                placeholder={'0.0000'}
                value={v_y_i_9 ? v_y_i_9 : ''}
                actions={{
                  change: handleV_y_i_9Change,
                }}
              />
            </LabelFrame25>
          </Container>

          {start_9 && finish_9 && v_y_i_9 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_9
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_9 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Объём по показаниям счётчика воды :
                <span style={{ margin: '0 4px' }}>{v_u_i_9} м³</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_9 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Относительная погрешность счётчика :
                <span style={{ margin: '0 4px' }}>{permissible_error_9} %</span>
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_9 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                Пределы допускаемой погрешности счётчика :
                <span style={{ margin: '0 4px' }}>± {q_max_limit} %</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default Indications
