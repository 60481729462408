const PresentIcon = () => {
  return (
    <svg width='71' height='71' viewBox='0 0 71 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.0019 5.86523C20.6985 5.87723 14.3327 6.09734 10.2941 10.1359C6.25554 14.1745 6.03543 20.5403 6.02344 32.8437H20.3545C19.3309 31.5651 18.566 30.0568 18.1489 28.3885C16.579 22.1089 22.2671 16.4208 28.5467 17.9907C30.215 18.4078 31.7233 19.1727 33.0019 20.1963V5.86523Z'
        fill='#0084E2'
      />
      <path
        d='M6.02344 37.2187C6.03543 49.5221 6.25554 55.888 10.2941 59.9265C14.3327 63.9651 20.6985 64.1852 33.0019 64.1972V41.2252C30.728 45.7671 26.0312 48.8854 20.6061 48.8854C19.398 48.8854 18.4186 47.906 18.4186 46.6979C18.4186 45.4898 19.398 44.5104 20.6061 44.5104C25.0884 44.5104 28.8441 41.3993 29.8316 37.2187H6.02344Z'
        fill='#0084E2'
      />
      <path
        d='M37.3769 64.1972C49.6803 64.1852 56.0462 63.9651 60.0847 59.9265C64.1233 55.888 64.3434 49.5221 64.3554 37.2187H40.5472C41.5348 41.3993 45.2904 44.5104 49.7727 44.5104C50.9809 44.5104 51.9602 45.4898 51.9602 46.6979C51.9602 47.906 50.9809 48.8854 49.7727 48.8854C44.3477 48.8854 39.6509 45.7671 37.3769 41.2252V64.1972Z'
        fill='#0084E2'
      />
      <path
        d='M64.3554 32.8437C64.3434 20.5403 64.1233 14.1745 60.0847 10.1359C56.0462 6.09734 49.6803 5.87723 37.3769 5.86523V20.1963C38.6556 19.1727 40.1638 18.4078 41.8321 17.9907C48.1117 16.4208 53.7998 22.1089 52.2299 28.3885C51.8128 30.0568 51.048 31.5651 50.0243 32.8437H64.3554Z'
        fill='#0084E2'
      />
      <path
        d='M27.4857 22.2351C30.7276 23.0456 33.0019 25.9585 33.0019 29.3002V32.8437H29.4584C26.1167 32.8437 23.2038 30.5694 22.3933 27.3274C21.6244 24.252 24.4102 21.4662 27.4857 22.2351Z'
        fill='#0084E2'
      />
      <path
        d='M37.3769 29.3002V32.8437H40.9204C44.2622 32.8437 47.175 30.5694 47.9855 27.3274C48.7544 24.252 45.9686 21.4662 42.8932 22.2351C39.6512 23.0456 37.3769 25.9585 37.3769 29.3002Z'
        fill='#0084E2'
      />
    </svg>
  )
}

export default PresentIcon
