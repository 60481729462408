import React, { ReactElement } from 'react'
import JournalsView from './views/JournalsView.tsx'
import TarrifsView from './views/TarrifsView.tsx'

const Settings: React.FC<{ view: 'journals' | 'tarrifs' }> = (props: {
  view: 'journals' | 'tarrifs'
}): ReactElement => {
  const { view } = props

  return (
    <React.Fragment>
      {view === 'journals' && <JournalsView />}
      {view === 'tarrifs' && <TarrifsView />}
    </React.Fragment>
  )
}

export default Settings
