// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { ReactElement, useEffect, useRef } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import theme from '../../utils/theme.ts'
import CheckCircleIcon from '../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../img/icon/VisibilityToggleIcon'

import { CSSObject } from 'styled-components'

interface IInput {
  value: string | number | null
  placeholder: string
  focused?: boolean
  type?: string
  pattern?: any
  length?: number
  className?: string
  label?: string | ReactElement
  style?: CSSObject
  autoComplete?: string
  isDisabled?: boolean
  notRequired?: boolean
  error?: boolean
  multiline?: boolean
  minRows?: number
  maxRows?: number
  sx?: CSSObject
  actions: {
    change?: Function | [Function, string | number | boolean | null | undefined | Object | []]
    keyUp?: [Function]
  }
}

const Input: React.FC<IInput> = ({
  type,
  focused = false,
  pattern = null,
  length = null,
  className,
  placeholder,
  label,
  style,
  value,
  actions,
  autoComplete = 'off',
  isDisabled = false,
  notRequired = false,
  error = false,
  multiline,
  minRows,
  maxRows,
  sx,
}) => {
  const isPassword = type === 'password'
  const [showPassword, setShowPassword] = React.useState(false)

  const inputRef = useRef<HTMLInputElement | null>()

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  useEffect(() => {
    if (focused) {
      inputRef.current?.querySelector('input')?.focus()
    }
  }, [focused])

  return (
    <FormControl fullWidth sx={{ m: 1, margin: '0px' }} style={style}>
      <InputLabel
        htmlFor='outlined-adornment-amount'
        shrink
        sx={{
          '&.MuiInputLabel-root': {
            color: theme.palette.text.dark,
            fontSize: '14px !important',
            fontWeight: '400 !important',
            background: 'white',
            padding: '0 8px',
            borderRadius: '12px',
          },
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        ref={inputRef}
        id='outlined-adornment-amount'
        type={isPassword ? (showPassword ? 'text' : 'password') : type}
        startAdornment={
          !notRequired && (
            <InputAdornment position='start' sx={sx}>
              <React.Fragment>
                {value ? (
                  <CheckCircleIcon sx={{ color: theme.palette.secondary.light }} />
                ) : (
                  <VisibilityToggleIcon sx={{ color: '#FFCF25' }} />
                )}
              </React.Fragment>
            </InputAdornment>
          )
        }
        endAdornment={
          isPassword && (
            <InputAdornment position='end'>
              <IconButton
                style={{ marginRight: '4px' }}
                aria-label='toggle password visibility'
                onClick={handleTogglePasswordVisibility}
                edge='end'
              >
                {showPassword ? (
                  <VisibilityIcon sx={{ color: '#63E6BE', fontSize: 20 }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: '#d7ddea', fontSize: 20 }} />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
        label={label}
        placeholder={placeholder}
        className={className}
        value={value}
        autoComplete={autoComplete}
        disabled={isDisabled}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        error={error}
        onChange={event => {
          try {
            if (typeof actions['change'] === 'function') {
              actions['change'](event.target.value)
            } else if (
              Array.isArray(actions['change']) &&
              typeof actions['change'][0] === 'function'
            ) {
              const previousValues = Array.isArray(actions['change'][1]) ? actions['change'][1] : []
              actions['change'][0]([...previousValues, event.target.value])
            }
          } catch (error) {
            console.error('ошибка отработки события onchange:', error)
          }
        }}
        onKeyUp={event => {
          actions['keyup'] && actions['keyup'][0](event.key)
        }}
        sx={{
          ...sx,
          borderRadius: '12px',
          backgroundColor: 'white',

          '& .MuiInputAdornment-root': {
            marginRight: '14px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
          '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.text.secondary}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.text.secondary}`,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: `1px solid ${theme.palette.background.light}`,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: `1px solid ${theme.palette.background.error}`,
          },
        }}
      />
    </FormControl>
  )
}

export default Input
