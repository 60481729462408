// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'

import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { styled } from '@mui/material/styles'

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    width: '100%',
    borderRadius: '6px',
    padding: '14px 8px',
    '&::before': {
      display: 'none',
    },
  })
)

const AccordionComponent = props => {
  const {
    title = '',
    data = [],
    dataType = 'default',
    iconSize = 'medium',
    backgroundColor = null,
    border = null,
    titleSize = null,
    titleMt = '-1px',
    padding = null,
    prSum = null,
    plSum = null,
    mt = null,
    isExpanded = false,
    expandedSwitcher = null,
    r = null,
    btlr = null,
    btrr = null,
    bblr = null,
    bbrr = null,
  } = props

  const [expandedDescription, setExpandedDescription] = useState(isExpanded)

  useEffect(() => {
    expandedSwitcher && expandedSwitcher(expandedDescription)
  }, [expandedDescription, expandedSwitcher])

  return (
    <React.Fragment>
      <Accordion
        onChange={() => {
          setExpandedDescription(prev => !prev)
        }}
        expanded={expandedDescription}
        style={{
          backgroundColor: backgroundColor ?? '#F8F9FC',
          border: border ?? 'none',
          boxShadow: 'none',
          borderRadius: r ? r : '12px',
          padding: padding ?? '14px 8px',
          marginTop: mt ? mt + 'px' : '0px',
          ...(btlr && { borderTopLeftRadius: btlr }),
          ...(btrr && { borderTopRightRadius: btrr }),
          ...(bblr && { borderBottomLeftRadius: bblr }),
          ...(bbrr && { borderBottomRightRadius: bbrr }),
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls='panel1-content'
          id='panel1-header'
          style={{
            fontSize: titleSize ?? '15px',
            fontWeight: 600,
            paddingTop: '0px',
            ...(prSum && { paddingRight: prSum }),
            ...(plSum && { paddingLeft: plSum }),
          }}
        >
          {expandedDescription ? (
            <IndeterminateCheckBoxIcon
              fontSize={iconSize}
              sx={{
                color: '#0084e2',
                marginRight: '10px',
                marginTop: '-0px',
              }}
            />
          ) : (
            <AddBoxIcon
              fontSize={iconSize}
              sx={{
                color: '#0084e2',
                marginRight: '10px',
                marginTop: '-0px',
              }}
            />
          )}
          <h3 style={{ marginTop: titleMt }}>{title}</h3>
        </AccordionSummary>
        <AccordionDetails>
          {dataType === 'default' && <React.Fragment></React.Fragment>}
          {dataType === 'custom' && <React.Fragment>{data}</React.Fragment>}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export default AccordionComponent
