// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import api from '../../../services/app/client-server/axios-config.service'
import Selector from '../LocalElements/Selector/reactSelect/Selector'
import { useDispatch, useSelector } from 'react-redux'

import D from '../../../store/localStorage/dispatcher'
import { setMeasurementId } from '../../../store/slices/app/views/meters'

const FilterMetersBase = ({ isDisabled = false }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const isClearable = true
  const isSearchable = true

  const measurement_id = useSelector(state => state.meterReducer.measurement_id)
  const token = useSelector(state => state.authReducer.authData.token)

  const options = data
    ? data.map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = selectedOption => {
    const newMeasurementId = selectedOption ? selectedOption.value : ''
    dispatch(setMeasurementId(newMeasurementId))
    D.set('selectedMeasurementId', newMeasurementId)
  }

  useEffect(() => {
    const fetchMeasurements = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/measurements/list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
        const response = await api.get(apiUrl, { headers })
        const measurements = response.data.measurements
        setData(measurements)

        const savedMeasurementId = D.get('selectedMeasurementId')
        if (savedMeasurementId) {
          dispatch(setMeasurementId(parseInt(savedMeasurementId, 10)))
        } else if (measurements.length > 0) {
          dispatch(setMeasurementId(measurements[0].id))
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchMeasurements()
  }, [])

  return (
    <React.Fragment>
      {/* <span
        style={{
          opacity: 0.8,
          display: 'block',
          letterSpacing: 0.5,
          marginBottom: '6px',
          fontWeight: 500,
        }}
      >
        Выберите специализацию прибора
      </span> */}
      <Selector
        placeholder={'Выберите специализацию прибора'}
        options={options}
        onChange={getValue}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={options.find(option => option.value === measurement_id)}
        noOptionsMessage={() => 'Нет доступных приборов'}
        isDisabled={isDisabled}
        // ----------------------------------------------------------------
        // label={{
        //   text: 'База приборов',
        //   topBg: '#f8f9fc',
        //   bottomBg: 'white',
        // }}
        // ----------------------------------------------------------------
      />
    </React.Fragment>
  )
}

export default FilterMetersBase
