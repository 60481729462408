// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import D from '../../../store/localStorage/dispatcher'
import R from '../../../services/app/client-server/request.service'

import emptyMeter from '../../../img/emptyMeter.jpg'

const CompanyShow = () => {
  const navigate = useNavigate()
  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const companyId = D.get('companyId')
  const [data, setData] = useState([])

  const fetchData = async () => {
    const { data: companiesItemData } = await R.getCompaniesItem(companyId)
    const data = companiesItemData.data

    setData(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <main className={'main'}>
      <React.Fragment>
        <div className='path_to_edit' style={{ justifyContent: 'space-between', margin: 0 }}>
          <h2>Компания "{data?.name}"</h2>
          <i
            className='fas fa-pencil-alt icon_back'
            onClick={() => navigate(`/metriva/company/${companyId}/edit`)}
          />
        </div>

        <div className='box'>
          <div className='flexContainer' style={{ justifyContent: 'space-between' }}>
            <div className='company-name-desc' style={{ width: '70%' }}>
              <h2
                style={{
                  display: 'block',
                  position: 'relative',
                  width: '100%',
                  marginBottom: '18px',
                }}
              >
                Общие данные о компании
              </h2>
              <div
                className='flexContainerWithGap'
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '22px',
                  paddingRight: '100px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: '40%',
                    lineHeight: '25px',
                  }}
                >
                  Генеральный директор:
                </span>
                <h4
                  style={{
                    letterSpacing: '0.6px',
                    width: '60%',
                    lineHeight: '25px',
                    fontWeight: 500,
                    marginTop: 0,
                  }}
                >
                  {data?.director_full_name}
                </h4>
              </div>
              <div
                className='flexContainerWithGap'
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '22px',
                  paddingRight: '100px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: '40%',
                    lineHeight: '25px',
                  }}
                >
                  Регион:
                </span>
                <h4
                  style={{
                    letterSpacing: '0.6px',
                    width: '60%',
                    lineHeight: '25px',
                    fontWeight: 500,
                    marginTop: 0,
                  }}
                >
                  {data?.region?.name}
                </h4>
              </div>
              <div
                className='flexContainerWithGap'
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '22px',
                  paddingRight: '100px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: '40%',
                    lineHeight: '25px',
                  }}
                >
                  Юридический адрес компании:
                </span>
                <h4
                  style={{
                    letterSpacing: '0.6px',
                    width: '60%',
                    lineHeight: '25px',
                    fontWeight: 500,
                    marginTop: 0,
                  }}
                >
                  {data?.address}
                </h4>
              </div>
              <div
                className='flexContainerWithGap'
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '22px',
                  paddingRight: '100px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: '40%',
                    lineHeight: '25px',
                  }}
                >
                  Номер телефона:
                </span>
                <h4
                  style={{
                    letterSpacing: '0.6px',
                    width: '60%',
                    lineHeight: '25px',
                    fontWeight: 500,
                    marginTop: 0,
                  }}
                >
                  {data?.phone}
                </h4>
              </div>
              <div
                className='flexContainerWithGap'
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '22px',
                  paddingRight: '100px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: '40%',
                    lineHeight: '25px',
                  }}
                >
                  Корпоративная почта:
                </span>
                <h4
                  style={{
                    letterSpacing: '0.6px',
                    width: '60%',
                    lineHeight: '25px',
                    fontWeight: 500,
                    marginTop: 0,
                  }}
                >
                  {data?.email}
                </h4>
              </div>
            </div>
            <div className='company-name-logo' style={{ width: '26.6%' }}>
              {data?.logo?.path ? (
                <img src={data?.logo?.path} alt='logo' className='company-name-logo-img' />
              ) : (
                <img src={emptyMeter} alt='logo' className='company-name-logo-img' />
              )}
            </div>
          </div>
        </div>

        <div className='box'>
          <h2 style={{ marginBottom: '18px' }}>Дополнительная информация</h2>

          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              Расчетный счет:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.checking_account}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              Корреспондентский счет:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.correspondent_account}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              Наименование банка:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.bank_name}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              Адрес филиала банка:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.bank_address}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              БИК банка:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.bic}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              ИНН:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.inn}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              КПП:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.kpp}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              ОГРН:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.bin}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              Шифр поверителя:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.sign_cipher}
            </h4>
          </div>
          <div
            className='flexContainerWithGap'
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '70%',
              marginBottom: '22px',
              paddingRight: '100px',
            }}
          >
            <span
              style={{
                display: 'block',
                width: '40%',
                lineHeight: '25px',
              }}
            >
              Номер аккредитации:
            </span>
            <h4
              style={{
                letterSpacing: '0.6px',
                width: '60%',
                lineHeight: '25px',
                fontWeight: 500,
                marginTop: 0,
              }}
            >
              {data?.accreditation_unique_id}
            </h4>
          </div>
        </div>
      </React.Fragment>
    </main>
  )
}

export default CompanyShow
