import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStepNumber } from '../../../../store/slices/app/views/protocols'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import api from '../../../../services/app/client-server/axios-config.service'

import { refreshProtocols } from '../../../../store/slices/app/controlers/updater'
import { resetCase } from '../../../../store/slices/app/views/protocols'
import { changeProperty, setDefaultState } from '../../Methodologies/water/Mi-1592-2015/state'
import { resetMeasurementId } from '../../../../store/slices/app/views/protocols'
import {
  refreshConditions,
  refreshPersonalMetrolog,
  refreshReceiveds,
  refreshTickets,
  refreshIssueCase,
} from '../../../../store/slices/app/controlers/updater'

import EastIcon from '@mui/icons-material/East'
import WestIcon from '@mui/icons-material/West'

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../../store/slices/app/comps/snackbar.js'

const Stepper = ({ steps }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()

  const enableNextStep = useSelector(state => state.stepperReducer.enableNextStep)
  const token = useSelector(state => state.authReducer.authData.token)
  const caseId = useSelector(state => state.protocolReducer.case.caseId)
  const verificationType = useSelector(state => state.protocolReducer.case.verificationType)
  const verificationTypeId = useSelector(state => state.protocolReducer.case.verificationTypeId)
  const pointId = useSelector(state => state.protocolReducer.case.pointId)
  const meterId = useSelector(state => state.mi15922015Reducer.meterId)

  const customerName = useSelector(state => state.mi15922015Reducer.customerName)
  const customerAddress = useSelector(state => state.mi15922015Reducer.customerAddress)
  const customerFullName = useSelector(state => state.mi15922015Reducer.customerFullName)
  const customerPhoneNumber = useSelector(state => state.mi15922015Reducer.customerPhoneNumber)

  const isMechanicalAccepted = useSelector(state => state.mi15922015Reducer.isMechanicalAccepted)
  const isTightnessAccepted = useSelector(state => state.mi15922015Reducer.isTightnessAccepted)

  const isMarkPassport = useSelector(state => state.mi15922015Reducer.isMarkPassport)
  const isMarkSi = useSelector(state => state.mi15922015Reducer.isMarkSi)
  const isTotalAccepted = useSelector(state => state.mi15922015Reducer.isTotalAccepted)

  const indications = useSelector(state => state.mi15922015Reducer.indications)
  const conditions = useSelector(state => state.mi15922015Reducer.conditions)

  const valueMod = useSelector(state => state.mi15922015Reducer.valueMod)
  const valueAdditional = useSelector(state => state.mi15922015Reducer.valueAdditional)
  const valueAnotherMod = useSelector(state => state.mi15922015Reducer.valueAnotherMod)
  const is_custom_modification = useSelector(
    state => state.mi15922015Reducer.is_custom_modification,
  )

  const meterFactoryNumber = useSelector(state => state.mi15922015Reducer.meterFactoryNumber)
  const meterYear = useSelector(state => state.mi15922015Reducer.meterYear)

  const diameter = useSelector(state => state.mi15922015Reducer.diameter)
  const meterLocation = useSelector(state => state.mi15922015Reducer.meterLocation)
  const meterType = useSelector(state => state.mi15922015Reducer.meterType)
  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_max = useSelector(state => state.mi15922015Reducer.q_max)
  const q_actual = useSelector(state => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)

  const verificationDate = useSelector(state => state.mi15922015Reducer.verificationDate)
  const nextVerificationDate = useSelector(state => state.mi15922015Reducer.nextVerificationDate)
  const nextVerificationDateAutoGenerate = useSelector(
    state => state.mi15922015Reducer.nextVerificationDateAutoGenerate,
  )

  const agreementNumber = useSelector(state => state.mi15922015Reducer.agreementNumber)

  const comment = useSelector(state => state.mi15922015Reducer.comment)
  const file = useSelector(state => state.mi15922015Reducer.file)
  const doChecked = useSelector(state => state.mi15922015Reducer.doChecked)

  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = steps.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSaveProtocol = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      }

      const data = {
        case_id: caseId,
        point_id: pointId,
        verification_type_id: verificationTypeId ? verificationTypeId : verificationType,
        customer_arshin_name: customerName,
        customer_address: customerAddress,
        customer_fullname: customerFullName,
        customer_phone: customerPhoneNumber,

        mechanical_accepted: isMechanicalAccepted,
        tightness_accepted: isTightnessAccepted,
        mark_passport: isMarkPassport,
        mark_si: isMarkSi,
        total_accepted: isTotalAccepted,

        indications: indications,
        conditions: conditions,

        meterable_id: meterId,

        details: {
          modification: valueMod === 'another' ? valueAnotherMod : valueMod,
          additional_option: valueAdditional ? valueAdditional : null,
          is_custom_modification: is_custom_modification,
          meter_factory_number: meterFactoryNumber,
          meter_factory_year: meterYear,
          diameter: parseFloat(diameter),
          orientation: meterLocation,
          meter_type: meterType,
          q_min: parseFloat(q_min),
          q_t: parseFloat(q_t),
          q_max: parseFloat(q_max),
          q_actual: parseFloat(q_actual),
          q_min_limit: parseFloat(q_min_limit),
          q_max_limit: parseFloat(q_max_limit),
        },

        verification_date: verificationDate,
        next_verification_date: nextVerificationDateAutoGenerate
          ? nextVerificationDateAutoGenerate
          : nextVerificationDate,
        agreement_number: agreementNumber,

        comment_files: comment,
        files: file,
        is_send: doChecked,
      }

      const request = {
        url: apiUrl,
        method: 'POST',
        payload: data,
        headers: headers,
      }

      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        if (!navigator.onLine) {
          navigator.serviceWorker.controller.postMessage({
            form_data: request,
          })
          navigator.serviceWorker.ready.then(function (registration) {
            return registration.sync.register('sendFormData')
          })
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(
            setMessage(
              'Протокол успешно создан. Данные будут отправлены на сервер, когда интернет появится',
            ),
          )
          navigate('/metriva/protocols')
        } else {
          await api.post(apiUrl, data, { headers })
          dispatch(setMessage('Протокол успешно создан'))
          dispatch(setType('success'))
          dispatch(setOpenAlert(true))
          dispatch(refreshProtocols())
          dispatch(resetCase())
          dispatch(setDefaultState())
          dispatch(resetMeasurementId())
          navigate('/metriva/protocols')

          dispatch(refreshConditions())
          dispatch(refreshPersonalMetrolog())
          dispatch(refreshReceiveds())
          dispatch(refreshTickets())
          dispatch(refreshIssueCase())
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const serverErrors = error.response.data.errors
        dispatch(setType('error'))
        dispatch(setOpenAlert(true))
        dispatch(setMessage('Ошибка валидации. Проверьте, что вы заполнили все поля!'))
        dispatch(changeProperty({ value: 'errors', label: serverErrors || {} }))
      }
    }
  }

  React.useEffect(() => {
    dispatch(setStepNumber(activeStep))
  }, [activeStep, dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='subtitle1' sx={{ mb: 1, letterSpacing: 1, textAlign: 'center' }}>
          Шаг {activeStep + 1} / {maxSteps}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mb: 3,
          }}
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor:
                  index <= activeStep ? 'rgba(0, 132, 226, 0.6)' : 'rgba(181, 188, 196, 1)',
                margin: '0 5px',
              }}
            />
          ))}
        </Box>
        {steps[activeStep].label && (
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 50,
              px: 2,
              bgcolor: 'background.default',
              mb: 2,
              width: '100%',
              maxWidth: 600,
              textAlign: 'center',
            }}
          >
            <Typography variant='h6'>{steps[activeStep].label}</Typography>
          </Paper>
        )}
      </Box>
      <Box
        className={'mobileStepsContent'}
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          width: '100%',
          maxWidth: 600,
          mx: 'auto',
        }}
      >
        {steps[activeStep].description}
      </Box>

      <Box
        sx={{
          flexShrink: 0,
          width: '100%',
          maxWidth: 600,
          mx: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '12px',
          paddingTop: '12px',
        }}
      >
        {true && (
          <Button
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{
              textTransform: 'none',
              fontSize: '15px',
              flexGrow: 1,
              padding: '16px 24px',
              borderRadius: '8px',
              border: '1.5px solid rgba(19, 37, 50, 0.15)',
              boxSizing: 'border-box',
              color: 'gray',
            }}
          >
            {theme.direction === 'rtl' ? (
              <EastIcon sx={{ transform: 'scale(1.1)', ml: 1 }} />
            ) : (
              <WestIcon sx={{ transform: 'scale(1.1)', ml: 1 }} />
            )}
          </Button>
        )}
        {activeStep + 1 !== maxSteps ? (
          <Button
            onClick={handleNext}
            disabled={!!!enableNextStep}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              flexGrow: 3,
              padding: '16px 24px',
              borderRadius: '8px',
              backgroundColor: '#0084E2',
              color: 'white',
              boxSizing: 'border-box',
              '&:disabled': {
                backgroundColor: 'grey',
              },
            }}
          >
            Продолжить
            {theme.direction === 'rtl' ? (
              <WestIcon sx={{ transform: 'scale(1.1)', mr: 1, marginLeft: '13px' }} />
            ) : (
              <EastIcon sx={{ transform: 'scale(1.1)', mr: 1, marginLeft: '13px' }} />
            )}
          </Button>
        ) : (
          <Button
            onClick={handleSaveProtocol}
            sx={{
              textTransform: 'none',
              fontSize: '16px',
              flexGrow: 3,
              padding: '16px 24px',
              borderRadius: '8px',
              backgroundColor: '#0084E2',
              color: 'white',
              boxSizing: 'border-box',
              '&:disabled': {
                backgroundColor: 'grey',
              },
            }}
          >
            Сохранить
            {theme.direction === 'rtl' ? (
              <WestIcon sx={{ transform: 'scale(1.1)', mr: 1, marginLeft: '13px' }} />
            ) : (
              <EastIcon sx={{ transform: 'scale(1.1)', mr: 1, marginLeft: '13px' }} />
            )}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Stepper
