import React, { useState, useEffect, useCallback, useRef, ChangeEvent } from 'react'
import CheckCircleIcon from '../../../../../img/icon/CheckCircleIcon'
import VisibilityToggleIcon from '../../../../../img/icon/VisibilityToggleIcon'

import { IOption, IOptionsInput } from '../../../../types'

const OptionsInput: React.FC<IOptionsInput> = ({
  callback,
  value,
  options = async () => ({ data: {} }),
  optionKey,
  placeholder = 'Начните вводить искомый адрес',
  label,
  isRequired = false,
}) => {
  const [inputVal, setInputVal] = useState<string | undefined>(value)
  const [suggestions, setSuggestions] = useState<IOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const loadOptions = useCallback(
    async (input: string) => {
      setLoading(true)
      try {
        const response = await options!(input)
        const data = response.data?.[optionKey] || []
        setSuggestions(data)
      } catch (error) {
        setSuggestions([])
      }
      setLoading(false)
    },
    [options, optionKey],
  )

  useEffect(() => {
    if (inputVal) {
      loadOptions(inputVal)
    } else {
      setSuggestions([])
    }
  }, [inputVal, loadOptions])

  useEffect(() => {
    setInputVal(value)
  }, [value])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSelect = (option: IOption) => {
    setInputVal(option.value)
    setSuggestions([])
    setIsOpen(false)
    callback(option)
  }

  const handleInputFocus = () => {
    setIsOpen(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value)
  }

  return (
    <div style={{ position: 'relative', backgroundColor: 'white' }} ref={containerRef}>
      {label && (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            position: 'absolute',
            zIndex: 2,
            marginTop: '-12px',
            marginLeft: '8px',
          }}
        >
          <span
            style={{
              display: 'block',
              position: 'relative',
              width: '100%',
              height: '12px',
              backgroundColor: label.topBg,
            }}
          />
          <span
            style={{
              display: 'block',
              position: 'relative',
              width: '100%',
              height: '12px',
              backgroundColor: label.bottomBg,
            }}
          />
          <span
            style={{
              display: 'block',
              position: 'relative',
              fontSize: '14px',
              marginTop: '-23px',
              padding: '0px 10px',
              color: '#4f5960',
            }}
          >
            {label.text}
          </span>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '12px',
          height: '55px',
          border: '1px solid #d7ddea',
          padding: '4px',
          paddingLeft: '14px',
        }}
      >
        {isRequired && (
          <React.Fragment>
            {inputVal ? (
              <CheckCircleIcon sx={{ color: '#63E6BE', marginLeft: '0px' }} />
            ) : (
              <VisibilityToggleIcon sx={{ color: '#FFD43B', marginLeft: '0px' }} />
            )}
          </React.Fragment>
        )}
        <input
          type='text'
          placeholder={placeholder}
          value={inputVal}
          onChange={handleChange}
          onFocus={handleInputFocus}
          style={{
            width: '100%',
            height: '90%',
            fontSize: '15px',
            padding: '4px',
            paddingLeft: '13px',
            borderRadius: '12px',
            border: 'none',
            outline: 'none',
          }}
        />
      </div>

      {isOpen && (
        <div
          className='hidden-scrollbar'
          style={{
            position: 'absolute',
            overflowX: 'hidden',
            overflowY: 'scroll',
            paddingTop: 10,
            paddingBottom: 9,
            width: '90%',
            marginTop: 10,
            border: 'none',
            zIndex: 20,
            maxHeight: '200px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          {loading ? (
            <div style={{ padding: '12px', fontSize: '14px', textAlign: 'center', color: 'gray' }}>
              Подождите, идет загрузка...
            </div>
          ) : suggestions.length > 0 ? (
            <div
              style={{
                margin: 0,
                padding: 0,
                listStyle: 'none',
              }}
            >
              {suggestions.map(opt => (
                <li
                  key={opt.value}
                  onClick={() => handleSelect(opt)}
                  className='suggestion-item'
                  style={{
                    fontSize: '13px',
                    padding: '12px 0',
                    paddingLeft: '13px',
                    paddingBottom: '12.8px',
                    lineHeight: '20px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee',
                    transition: 'all 400ms',
                  }}
                >
                  {opt.value}
                </li>
              ))}
            </div>
          ) : !!!inputVal ? (
            <div style={{ padding: '12px', fontSize: '14px', textAlign: 'center', color: 'gray' }}>
              Начните вводить текст
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default OptionsInput
