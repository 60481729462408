// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

import useGeneratePDataOptical from '../../../../../hooks/useGeneratePDataOpticalMi1592'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import { setShow } from '../../../../../../store/slices/app/comps/feedback'

import Input from '../../../../LocalElements/Input/InputMUI'
import R from '../../../../../../services/app/client-server/request.service'

import { changeProperty } from '../state'

import css from '../style.css'

const {
  Container,
  IndicationBox,
  IndicationBoxLastElement,
  CountIndicationBox,
  QActualBox,
  InputQActualElement,
  ParagraphIndications,
  ConversionFactor,
} = css

const OpticalSensor = () => {
  const { generateValuesStartEnd, generateQactual, defaultValues } = useGeneratePDataOptical()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [tariffIds, setTariffIds] = useState([])
  const [startEndDataUpdate, setStartEndDataUpdate] = useState(false)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const diameter = useSelector(state => state.mi15922015Reducer.diameter)
  const q_min = useSelector(state => state.mi15922015Reducer.q_min)
  const q_t = useSelector(state => state.mi15922015Reducer.q_t)
  const q_actual = useSelector(state => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector(state => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector(state => state.mi15922015Reducer.q_max_limit)
  const conversion_factor = useSelector(state => state.mi15922015Reducer.conversion_factor)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_ActualChange = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_actual', label: value }))
  }

  const handleConversionFactorChange = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(
      changeProperty({
        value: 'conversion_factor',
        label: value,
      }),
    )
  }

  const q_i_1 = useSelector(state => state.mi15922015Reducer.q_i_1)
  const q_i_2 = useSelector(state => state.mi15922015Reducer.q_i_2)
  const q_i_3 = useSelector(state => state.mi15922015Reducer.q_i_3)

  const v_y_i_1 = useSelector(state => state.mi15922015Reducer.v_y_i_1)
  const v_y_i_2 = useSelector(state => state.mi15922015Reducer.v_y_i_2)
  const v_y_i_3 = useSelector(state => state.mi15922015Reducer.v_y_i_3)

  const impulses_1 = useSelector(state => state.mi15922015Reducer.impulses_1)
  const impulses_2 = useSelector(state => state.mi15922015Reducer.impulses_2)
  const impulses_3 = useSelector(state => state.mi15922015Reducer.impulses_3)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_1Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_i_1', label: value }))
  }
  const handleQ_i_2Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_i_2', label: value }))
  }
  const handleQ_i_3Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'q_i_3', label: value }))
  }

  const handleImpulses_1Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'impulses_1', label: value }))
  }
  const handleImpulses_2Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'impulses_2', label: value }))
  }
  const handleImpulses_3Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'impulses_3', label: value }))
  }

  const handleV_y_i_1Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'v_y_i_1', label: value }))
  }
  const handleV_y_i_2Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'v_y_i_2', label: value }))
  }
  const handleV_y_i_3Change = param => {
    const value = param.replace(',', '.').replace(/[^0-9.]/g, '')
    dispatch(changeProperty({ value: 'v_y_i_3', label: value }))
  }

  const v_u_i_1 = parseFloat(conversion_factor) * parseFloat(impulses_1)
  const v_u_i_2 = parseFloat(conversion_factor) * parseFloat(impulses_2)
  const v_u_i_3 = parseFloat(conversion_factor) * parseFloat(impulses_3)

  const permissible_error_1 = (((parseFloat(v_u_i_1) - v_y_i_1) / v_y_i_1) * 100).toFixed(2)
  const permissible_error_2 = (((parseFloat(v_u_i_2) - v_y_i_2) / v_y_i_2) * 100).toFixed(2)
  const permissible_error_3 = (((parseFloat(v_u_i_3) - v_y_i_3) / v_y_i_3) * 100).toFixed(2)

  useEffect(() => {
    console.group()
    false && console.log('q_i_1 - ', q_i_1)
    false && console.log('impulse_1 - ', impulses_1)
    false && console.log('v_y_i_1 - ', v_y_i_1)
    false &&
      false &&
      console.log(
        `v_u_i_1 - conversion_factor * impulses_1 = ${conversion_factor} * ${impulses_1} =`,
        conversion_factor * impulses_1,
      )
    false &&
      false &&
      console.log(
        `относительная погрешность - ( v_u_i_1 - v_y_i_1 ) / v_y_i_1 * 100 = ( ${v_u_i_1} - ${v_y_i_1} ) / ${v_y_i_1} * 100 =`,
        ((v_u_i_1 - v_y_i_1) / v_y_i_1) * 100,
      )
    console.groupEnd()
  }, [q_i_1, impulses_1, v_y_i_1, conversion_factor, v_u_i_1])

  // ----------------------------------------------------------------
  // vui = conversion_factor * impulses
  // permissionError = ( vyi - vui ) / vui * 100
  // ----------------------------------------------------------------

  const indicationPass_1 =
    permissible_error_1 >= -parseFloat(q_min_limit) &&
    permissible_error_1 <= parseFloat(q_min_limit)

  const indicationPass_2 =
    permissible_error_2 >= -parseFloat(q_max_limit) &&
    permissible_error_2 <= parseFloat(q_max_limit)

  const indicationPass_3 =
    permissible_error_3 >= -parseFloat(q_max_limit) &&
    permissible_error_3 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indicationsPass',
        label: [indicationPass_1, indicationPass_2, indicationPass_3],
      }),
    )
  }, [indicationPass_1, indicationPass_2, indicationPass_3, dispatch])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: 'indications',
        label: [
          {
            conversion_factor: conversion_factor,
            q_i: q_i_1,
            impulses: impulses_1,
            v_y_i: v_y_i_1,
            v_u_i: v_u_i_1,
            permissible_error: permissible_error_1,
          },
          {
            conversion_factor: conversion_factor,
            q_i: q_i_2,
            impulses: impulses_2,
            v_y_i: v_y_i_2,
            v_u_i: v_u_i_2,
            permissible_error: permissible_error_2,
          },
          {
            conversion_factor: conversion_factor,
            q_i: q_i_3,
            impulses: impulses_3,
            v_y_i: v_y_i_3,
            v_u_i: v_u_i_3,
            permissible_error: permissible_error_3,
          },
        ],
      }),
    )
  }, [
    conversion_factor,
    q_i_1,
    impulses_1,
    v_y_i_1,
    v_u_i_1,
    permissible_error_1,
    q_i_2,
    impulses_2,
    v_y_i_2,
    v_u_i_2,
    permissible_error_2,
    q_i_3,
    impulses_3,
    v_y_i_3,
    v_u_i_3,
    permissible_error_3,
    dispatch,
  ])

  useEffect(() => {
    ;(async () => {
      const { data } = await R.getUserRegions()

      const tariffIds = data.tariffs.map(tariff => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={width <= 1050 ? { marginTop: '0px' } : {}}>
        <h2 className='width50Percent' style={{ marginBottom: '20px' }}>
          Определение относительной погрешности
        </h2>

        {tariffIds && tariffIds.includes(5) && (
          <div className='width50Percent'>
            <Container style={width > 1050 ? {} : { gap: '14px' }}>
              <div className='width50Percent'>
                {!!!startEndDataUpdate && (
                  <Button
                    disabled={q_min && q_t && q_min_limit && conversion_factor ? false : true}
                    onMouseDown={() => {
                      generateQactual()
                    }}
                    onClick={() => {
                      setStartEndDataUpdate(true)
                      dispatch(setShow(true))

                      setTimeout(() => {
                        generateValuesStartEnd()
                        setStartEndDataUpdate(false)
                        dispatch(setShow(false))
                      }, 1100)
                    }}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '10px 20px 12px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Тестовая среда
                  </Button>
                )}
                {startEndDataUpdate && (
                  <Button
                    disabled={true}
                    variant='contained'
                    style={{
                      textTransform: 'none',
                      fontSize: '15px',
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: '10px 20px 12px',
                      borderRadius: '12px',
                      marginRight: '0px',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      <CircularProgress
                        variant='determinate'
                        sx={{
                          color: theme =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant='indeterminate'
                        disableShrink
                        sx={{
                          color: theme => (theme.palette.mode === 'light' ? 'grey' : 'grey'),
                          animationDuration: '550ms',
                          position: 'absolute',
                          opacity: 0.6,
                          left: 0,
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                        }}
                        size={30}
                        thickness={4}
                      />
                    </Box>
                  </Button>
                )}
              </div>

              <div className='width50Percent'>
                <Button
                  disabled={q_min && q_t && q_min_limit && conversion_factor ? false : true}
                  onClick={() => {
                    setStartEndDataUpdate(true)

                    setTimeout(() => {
                      defaultValues()
                      setStartEndDataUpdate(false)
                    }, 1100)
                  }}
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '10px 20px 12px',
                    borderRadius: '12px',
                    marginRight: '0px',
                    width: '100%',
                  }}
                >
                  Сбросить все значения
                </Button>
              </div>
            </Container>
            <ParagraphIndications
              style={width <= 1050 ? { textAlign: 'center', width: '100%' } : {}}
            >
              Генерация данных приведена как пример в помощь поверителю. При создании протокола все
              данные необходимо скорректировать.
            </ParagraphIndications>
          </div>
        )}
      </div>

      <ConversionFactor margin={'0 0 22px'}>
        <Input
          isDisabled={startEndDataUpdate}
          type={'text'}
          placeholder={'0.00000814'}
          label={'Коэффициент преобразования'}
          value={conversion_factor}
          actions={{
            change: handleConversionFactorChange,
          }}
        />
      </ConversionFactor>

      <h4 style={{ marginBottom: '26px' }}>
        {q_min ? `Введенное значение Qmin : ${q_min}, м³/ч` : 'Введите Qmin (описание типа)'}
      </h4>

      <React.Fragment>
        <Container margin={width > 1050 ? '0px 0px 22px' : '0px 0px 22px'}>
          <CountIndicationBox>
            <span style={{ fontSize: '14px', fontWeight: '500' }}>1</span>
          </CountIndicationBox>

          <IndicationBox margin={'0'}>
            <Input
              isDisabled={startEndDataUpdate}
              type={'text'}
              placeholder={'0.6830'}
              label={'Расход во время измерения, м³/ч'}
              value={q_i_1}
              actions={{
                change: handleQ_i_1Change,
              }}
            />
          </IndicationBox>

          <IndicationBox margin={'0'}>
            <Input
              isDisabled={startEndDataUpdate}
              type={'text'}
              placeholder={'2000'}
              label={'Количество импульсов, имп.'}
              value={impulses_1}
              actions={{
                change: handleImpulses_1Change,
              }}
            />
          </IndicationBox>

          <IndicationBox margin={'0'}>
            <Input
              isDisabled={false}
              type={'text'}
              placeholder={'0.024665'}
              label={'Объём воды, м³'}
              value={v_u_i_1 ? v_u_i_1.toFixed(4) : 'Автозаполнение'}
            />
          </IndicationBox>

          <IndicationBox margin={'0'}>
            <Input
              isDisabled={startEndDataUpdate}
              type={'text'}
              placeholder={'0.015233'}
              label={'Объем, по показаниям эт., м³'}
              value={v_y_i_1}
              actions={{
                change: handleV_y_i_1Change,
              }}
            />
          </IndicationBox>
        </Container>

        {q_i_1 && impulses_1 && v_y_i_1 && (
          <div
            className={`indications_box-gas_bottom ${
              indicationPass_1
                ? 'indications_box-gas_bottom_true'
                : 'indications_box-gas_bottom_false'
            }`}
            style={{ marginBottom: '22px' }}
          >
            <p
              className={`indications_box-gas_bottom ${
                indicationPass_1 ? 'text_true' : 'text_false'
              }`}
              style={{ margin: '0 2px' }}
            >
              {width > 1050 ? 'Относительная погрешность счетчика:' : 'Относительная погрешность:'}
              <span style={{ margin: '0 4px' }}>{permissible_error_1} %</span>
            </p>
            <p
              className={`indications_box-gas_bottom ${
                indicationPass_1 ? 'text_true' : 'text_false'
              }`}
              style={{ margin: '0 2px' }}
            >
              {width > 1050 ? 'Пределы допускаемой погрешности счётчика:' : 'Пределы погрешности:'}
              <span style={{ margin: '0 4px' }}>±{q_min_limit} %</span>
            </p>
          </div>
        )}
      </React.Fragment>

      <React.Fragment>
        <h4 style={{ marginBottom: '26px' }}>
          {q_t
            ? `Введенное значение 1.1 * Qt : ${(1.1 * q_t).toFixed(3)}, м³/ч`
            : 'Введите Qt (описание типа)'}
        </h4>

        <React.Fragment>
          <Container margin={width > 1050 ? '0px 0px 22px' : '0px 0px 0px'}>
            <CountIndicationBox>
              <span style={{ fontSize: '14px', fontWeight: '500' }}>2</span>
            </CountIndicationBox>

            <IndicationBox margin={'0'}>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'0.6830'}
                label={'Расход во время измерения, м³/ч'}
                value={q_i_2}
                actions={{
                  change: handleQ_i_2Change,
                }}
              />
            </IndicationBox>

            <IndicationBox margin={'0'}>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'2000'}
                label={'Количество импульсов, имп.'}
                value={impulses_2}
                actions={{
                  change: handleImpulses_2Change,
                }}
              />
            </IndicationBox>

            <IndicationBox margin={'0'}>
              <Input
                isDisabled={false}
                type={'text'}
                placeholder={'0.024665'}
                label={'Объём воды, м³'}
                value={v_u_i_2 ? v_u_i_2.toFixed(4) : 'Автозаполнение'}
              />
            </IndicationBox>

            <IndicationBox margin={'0'}>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'0.016467'}
                label={'Объем, по показаниям эт., м³'}
                value={v_y_i_2}
                actions={{
                  change: handleV_y_i_2Change,
                }}
              />
            </IndicationBox>
          </Container>

          {q_i_2 && impulses_2 && v_y_i_2 && (
            <div className='testimony__box-items-bottom'>
              <div
                className={`indications_box-gas_bottom ${
                  indicationPass_2
                    ? 'indications_box-gas_bottom_true'
                    : 'indications_box-gas_bottom_false'
                }`}
                style={{ marginBottom: '22px' }}
              >
                <p
                  className={`indications_box-gas_bottom ${
                    indicationPass_2 ? 'text_true' : 'text_false'
                  }`}
                  style={{ margin: '0 2px' }}
                >
                  {width > 1050
                    ? 'Относительная погрешность счетчика:'
                    : 'Относительная погрешность:'}
                  <span style={{ margin: '0 4px' }}>{permissible_error_2} %</span>
                </p>
                <p
                  className={`indications_box-gas_bottom ${
                    indicationPass_2 ? 'text_true' : 'text_false'
                  }`}
                  style={{ margin: '0 2px' }}
                >
                  {width > 1050
                    ? 'Пределы допускаемой погрешности счётчика:'
                    : 'Пределы погрешности:'}
                  <span style={{ margin: '0 4px' }}>±{q_max_limit} %</span>
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>

      <React.Fragment>
        <QActualBox margin={'0 0 23px'}>
          <h4>Введите значение Qmax (фактический) :</h4>
          <InputQActualElement>
            <Input
              isDisabled={startEndDataUpdate}
              type={'text'}
              placeholder={'Qmax фактический'}
              value={q_actual}
              actions={{
                change: handleQ_ActualChange,
              }}
              className={'backgroundColorWhite'}
            />
          </InputQActualElement>
        </QActualBox>

        <React.Fragment>
          <Container margin={'0px 0px 0px'}>
            <CountIndicationBox>
              <span style={{ fontSize: '14px', fontWeight: '500' }}>3</span>
            </CountIndicationBox>
            <IndicationBox>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'0.6830'}
                label={'Расход во время измерения, м³/ч'}
                value={q_i_3}
                actions={{
                  change: handleQ_i_3Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'2000'}
                label={'Количество импульсов, имп.'}
                value={impulses_3}
                actions={{
                  change: handleImpulses_3Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={false}
                type={'text'}
                placeholder={'0.024665'}
                label={'Объём воды, м³'}
                value={v_u_i_3 ? v_u_i_3.toFixed(4) : 'Автозаполнение'}
              />
            </IndicationBox>

            <IndicationBoxLastElement>
              <Input
                isDisabled={startEndDataUpdate}
                type={'text'}
                placeholder={'0.024765'}
                label={'Объем, по показаниям эт., м³'}
                value={v_y_i_3}
                actions={{
                  change: handleV_y_i_3Change,
                }}
              />
            </IndicationBoxLastElement>
          </Container>

          {q_i_3 && impulses_3 && v_y_i_3 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_3
                  ? 'indications_box-gas_bottom_true'
                  : 'indications_box-gas_bottom_false'
              }`}
              style={{ marginTop: '22px', marginBottom: '0px' }}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                {width > 1050
                  ? 'Относительная погрешность счетчика:'
                  : 'Относительная погрешность:'}
                <span style={{ margin: '0 4px' }}>{permissible_error_3} %</span>
              </p>

              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? 'text_true' : 'text_false'
                }`}
                style={{ margin: '0 2px' }}
              >
                {width > 1050
                  ? 'Пределы допускаемой погрешности счётчика:'
                  : 'Пределы погрешности:'}
                <span style={{ margin: '0 4px' }}>±{q_max_limit} %</span>
              </p>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  )
}

export default OpticalSensor
