import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  enableNextStep: false,
}

export const stepperReducer = createSlice({
  name: 'stepperReducer',
  initialState,
  reducers: {
    toggleEnableStep: (state, action) => {
      state.enableNextStep = action.payload
    },
  },
})

export const { toggleEnableStep } = stepperReducer.actions
export default stepperReducer.reducer
