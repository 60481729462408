// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { history } from './history.ts'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { store } from './store/store'
import { ThemeProvider } from '@mui/material/styles'
import theme from './utils/theme.ts'

import { StyledEngineProvider } from '@mui/material/styles'

import App from './App'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js').then(event => {
    false && console.log('sw зарегистрирован', event)
  })
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <HistoryRouter history={history}>
    <Provider store={store}>
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </Provider>
  </HistoryRouter>,
)
