import React from 'react'
import Input from '../LocalElements/Input/InputMUI'

import { useDispatch, useSelector } from 'react-redux'
import { changeMainData } from '../../../store/slices/app/views/companies'

import useWindowDimensions from '../../hooks/useWindowDimensions'

const NameCompany = ({ validationErrors }) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const name = useSelector(state => state.companiesReducer.createData.main.name)
  const ceo = useSelector(state => state.companiesReducer.createData.main.ceo)
  const number = useSelector(state => state.companiesReducer.createData.main.number)
  const email = useSelector(state => state.companiesReducer.createData.main.email)

  const formatPhoneNumber = input => {
    const phoneNumber = input.replace(/\D/g, '')
    let formattedPhoneNumber = ''

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`
    }

    dispatch(changeMainData({ value: 'number', label: formattedPhoneNumber }))
  }

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleNameCompanyChange = param => {
    const capitalizedValue = capitalizeFirstLetter(param)
    dispatch(changeMainData({ value: 'name', label: capitalizedValue }))
  }

  const handleDirectorChange = param => {
    const value = param.replace(/[^\D]/g, '')
    const capitalizedValue = capitalizeFirstLetter(value)
    dispatch(changeMainData({ value: 'ceo', label: capitalizedValue }))
  }

  const handlePhoneNumberChange = param => {
    formatPhoneNumber(param)
  }

  const handleEmailCompanyChange = param => {
    const validEmail = param.replace(/[^a-zA-Z0-9@._-]/g, '')
    dispatch(changeMainData({ value: 'email', label: validEmail }))
  }

  return (
    <React.Fragment>
      <div className='flexContainerWithGap' style={{ marginBottom: '24px' }}>
        <div className='width50Percent'>
          <Input
            type={'text'}
            label={'Наименование компании'}
            placeholder={'OOO или ИП'}
            value={name}
            actions={{
              change: handleNameCompanyChange,
            }}
          />
          {!name && validationErrors['name'] && (
            <div className='error'>{validationErrors['name']}</div>
          )}
        </div>
        <div className='width50Percent'>
          <Input
            type={'text'}
            label={'Генеральный директор'}
            placeholder={'Введите ФИО'}
            value={ceo}
            actions={{
              change: handleDirectorChange,
            }}
          />
          {!ceo && validationErrors['director_full_name'] && (
            <div className='error'>{validationErrors['director_full_name']}</div>
          )}
        </div>
      </div>
      <div className='flexContainerWithGap' style={{ marginBottom: '15px' }}>
        <div className='width50Percent'>
          <Input
            type={'text'}
            label={'Номер телефона'}
            placeholder={'+7 (ХХХ) ХХХ-ХХ-ХХ'}
            value={number}
            actions={{
              change: handlePhoneNumberChange,
            }}
          />
          {validationErrors['phone'] && <div className='error'>{validationErrors['phone']}</div>}
        </div>
        <div className='width50Percent'>
          <Input
            type={'text'}
            label={'Корпоративная почта'}
            placeholder={'user@gmail.com'}
            value={email}
            actions={{
              change: handleEmailCompanyChange,
            }}
          />
          {validationErrors['email'] && <div className='error'>{validationErrors['email']}</div>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default NameCompany
