// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'

import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import FilterMetersBase from '../../components/Meters/FilterMetersBase'
import TableWater from '../../components/Meters/MeterWater/Tab'
import TableGas from '../../components/Meters/MeterGas/Tab'
import Selector from '../../components/LocalElements/Selector/reactSelect/Selector'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import SearchIcon from '../../../img/icon/SearchIcon'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'
import filterIcon from '../../../img/filter.svg'

import RightSideContainer from '../../components/LocalElements/RightSideContainer/RightSideContainer'

import { setSearchQuery } from '../../../store/slices/app/views/meters'
import { refreshMeters } from '../../../store/slices/app/controlers/updater'
import { setOpen as setOpenRightContainer } from '../../../store/slices/app/comps/rightContainer'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'
import {
  changeFilterParams,
  changeFilterMethods,
  changeFilterTitles,
  changeFilterCustomers,
} from '../../../store/slices/app/views/meters'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import R from '../../../services/app/client-server/request.service'

const Meters = () => {
  const userRole = useSelector(state => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mainRef = useRef()
  const { width } = useWindowDimensions()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)

  const measurement_id = useSelector(state => state.meterReducer.measurement_id)
  const selectedMeters = useSelector(state => state.meterReducer.selectedMeters)

  const customersDataForFilter = useSelector(state => state.meterReducer.filterParams.customers)
  const titlesDataForFilter = useSelector(state => state.meterReducer.filterParams.titles)
  const methodsDataForFilter = useSelector(state => state.meterReducer.filterParams.methods)

  const methods = useSelector(state => state.meterReducer.filterParams.selectedMethods)
  const customers = useSelector(state => state.meterReducer.filterParams.selectedCustomers)
  const titles = useSelector(state => state.meterReducer.filterParams.selectedTitles)
  const linkSI = useSelector(state => state.meterReducer.filterParams.isLinkSI)
  const pdf = useSelector(state => state.meterReducer.filterParams.isPDF)

  const [searchVisible, setSearchVisible] = useState(false)
  const [scrollSearchVisible, setScrollSearchVisible] = useState(false)

  const count = useMemo(() => {
    let filtersCount = 0

    if (methods.length > 0) filtersCount++
    if (customers) filtersCount++
    if (titles) filtersCount++
    if (linkSI) filtersCount++
    if (pdf) filtersCount++

    if (methods.length === 0 && !customers && !titles && !linkSI) filtersCount = 0

    false && console.log(methods, customers, titles, linkSI)

    return filtersCount
  }, [methods, customers, titles, linkSI, pdf])

  const [searchQueryLocal, setSearchQueryLocal] = useState('')

  const changeSearchQuery = param => {
    setSearchQueryLocal(param)
    dispatch(setSearchQuery(param))
  }

  const deleteMeters = async () => {
    const { status, data } = await R.deleteMeters(measurement_id, {
      ids: selectedMeters,
    })

    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Приборы были успешно удалены'))

      dispatch(refreshMeters())
    }

    false && console.log(data)
  }

  useEffect(() => {
    false &&
      false &&
      console.log({
        ids: selectedMeters,
      })
  }, [selectedMeters])

  useEffect(() => {
    // ----------------------------------------------------------------
    // доработать эту логику постепенно
    // ----------------------------------------------------------------

    if (searchQueryLocal.length > 0) {
      setTimeout(() => {
        false && setSearchVisible(true)
      }, 300)
    }
  }, [searchQueryLocal])

  return (
    <main
      ref={mainRef}
      className={'main'}
      onScroll={() => {
        if (mainRef.current.scrollTop > 600) {
          setScrollSearchVisible(true)
          setSearchVisible(false)
        } else if (mainRef.current.scrollTop < 500) {
          setScrollSearchVisible(false)
        }
      }}
    >
      <RightSideContainer blockTitle={'Фильтры'}>
        <div className='right-side-container'>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Завод-изготовитель
            </span>
            <div style={{ width: '100%' }}>
              <Selector
                placeholder={'Выберите один вариант'}
                options={customersDataForFilter}
                onChange={option => {
                  dispatch(changeFilterCustomers(option.label))
                }}
                value={
                  customers
                    ? customersDataForFilter.find(option => option.label === customers)
                    : null
                }
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </div>
          </div>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Наименование и тип СИ
            </span>
            <div style={{ width: '100%' }}>
              <Selector
                placeholder={'Начните вводить название'}
                options={titlesDataForFilter}
                onChange={option => {
                  dispatch(changeFilterTitles(option.label))
                }}
                value={titlesDataForFilter.find(option => option.label === titles) || null}
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </div>
          </div>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Методика поверки
            </span>
            <div style={{ width: '100%' }}>
              <Selector
                placeholder={'Выберите одну или несколько'}
                options={methodsDataForFilter}
                onChange={options => {
                  dispatch(changeFilterMethods(options.map(option => option.label)))
                }}
                isMulti
                value={methods.map(method => ({ value: method, label: method }))}
                isClearable={true}
                isSearchable={true}
                noOptionsMessage={() => 'Нет доступных вариантов'}
              />
            </div>
          </div>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              Ссылка на ФГИС Аршин
            </span>
            <div style={{ width: '100%' }}>
              {!!!false && (
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={linkSI || null}
                  onChange={event => {
                    false && console.log(event.target.value)

                    dispatch(
                      changeFilterParams({
                        value: 'isLinkSI',
                        label: event.target.value,
                      }),
                    )
                  }}
                  style={{
                    flexDirection: 'row',
                    fontFamily: "'Wix Madefor Display', sans-serif",
                    color: '#123532',
                    fontSize: '16px',
                    fontWeight: '400',
                    marginTop: '0px',
                    paddingLeft: '5px',
                  }}
                >
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                          },
                        }}
                      />
                    }
                    label='Нет ссылки'
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: '15px !important',
                        fontWeight: 400,
                        color: '#132532',
                      },
                    }}
                  />
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                          },
                        }}
                      />
                    }
                    label='Есть ссылка'
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: '15px !important',
                        fontWeight: 400,
                        color: '#132532',
                      },
                    }}
                  />
                </RadioGroup>
              )}
            </div>
          </div>
          <div className='right-side-container-block'>
            <span
              style={{
                display: 'block',
                width: '100%',
                fontSize: '15px',
                fontWeight: 600,
              }}
            >
              PDF с описанием типа
            </span>
            <div style={{ width: '100%' }}>
              {!!!false && (
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={pdf || null}
                  onChange={event => {
                    false && console.log(event.target.value)

                    dispatch(
                      changeFilterParams({
                        value: 'isPDF',
                        label: event.target.value,
                      }),
                    )
                  }}
                  style={{
                    flexDirection: 'row',
                    fontFamily: "'Wix Madefor Display', sans-serif",
                    color: '#123532',
                    fontSize: '16px',
                    fontWeight: '400',
                    marginTop: '0px',
                    paddingLeft: '5px',
                  }}
                >
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                          },
                        }}
                      />
                    }
                    label='PDF отсутствует'
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: '15px !important',
                        fontWeight: 400,
                        color: '#132532',
                      },
                    }}
                  />
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '22px !important',
                          },
                        }}
                      />
                    }
                    label='PDF присутствует'
                    sx={{
                      '& .MuiTypography-root': {
                        fontFamily: "'Wix Madefor Display', sans-serif !important",
                        fontSize: '15px !important',
                        fontWeight: 400,
                        color: '#132532',
                      },
                    }}
                  />
                </RadioGroup>
              )}
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <Button
              variant='outlined'
              onClick={() => {
                dispatch(changeFilterCustomers(''))
                dispatch(changeFilterTitles(''))
                dispatch(changeFilterMethods([]))
                dispatch(
                  changeFilterParams({
                    value: 'isLinkSI',
                    label: null,
                  }),
                )
                dispatch(
                  changeFilterParams({
                    value: 'isPDF',
                    label: null,
                  }),
                )
              }}
              style={{
                width: '100%',
                textTransform: 'none',
                fontSize: '15px',
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: '11px 20px 11px',
                borderRadius: '12px',
              }}
            >
              Сбросить все значения
            </Button>
          </div>
        </div>
      </RightSideContainer>
      <div className='header_box'>
        <div
          className='flexContainerWithGap'
          style={
            width > 1050
              ? {
                flexWrap: 'wrap',
                width: '100%',
                gap: '12px',
                position: 'relative',
              }
              : {
                width: !!!scrollSearchVisible ? '100%' : '100vw',
                gap: '12px',
                marginTop: !!!scrollSearchVisible ? '0px' : '-1px',
                marginBottom: '0px',
                flexDirection: 'row',
                alignItems: 'center',
                position: !!!scrollSearchVisible ? 'relative' : 'fixed',
                backgroundColor: !!!scrollSearchVisible ? 'transparent' : '#f8f9fc',
                paddingTop: !!!scrollSearchVisible ? '0px' : '10px',
                paddingBottom: !!!scrollSearchVisible ? '0px' : '14px',
                paddingLeft: !!!scrollSearchVisible ? '0px' : '20px',
                paddingRight: !!!scrollSearchVisible ? '0px' : '30px',
                boxShadow: !!!scrollSearchVisible
                  ? 'none'
                  : '0 1px 2px rgba(62, 62, 62, 0.12), 0 1px 2px rgba(62, 62, 62, 0.12)',
                zIndex: 3,
                left: scrollSearchVisible && 0,
                top: scrollSearchVisible && '60px',
              }
          }
        >
          {true && (
            <div className='selector' style={{ width: '100%', position: 'relative' }}>
              <div
                style={{
                  width: width > 1050 ? '605px' : '100%',
                  marginBottom: '0px',
                }}
              >
                <FilterMetersBase />
              </div>
              {width < 1050 && searchVisible ? (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    alignItems: 'center',
                    width: '100%',
                    opacity: searchVisible,
                    top: 0,
                  }}
                >
                  <SearchQuery searchQuery={searchQueryLocal} setSearchQuery={changeSearchQuery} />
                  <CloseIcon
                    onClick={event => {
                      event.stopPropagation()
                      setSearchVisible(false)
                      changeSearchQuery('')
                    }}
                    style={{
                      display: 'block',
                      position: 'absolute',
                      fontSize: '28px',
                      color: 'gray',
                      left: '100%',
                      marginLeft: '-40px',
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}

          {width <= 1050 && !!!searchVisible ? (
            <React.Fragment>
              <SearchIcon
                onClick={() => setSearchVisible(true)}
                style={{
                  display: 'block',
                  fontSize: '24px',
                  color: 'rgb(0, 132, 226)',
                  marginLeft: '6px',
                }}
              />
              <div style={{ marginTop: '3px' }}>
                <img
                  alt={''}
                  src={filterIcon}
                  onClick={() => {
                    dispatch(setOpenRightContainer(true))
                  }}
                />
                <span
                  style={{
                    display: 'block',
                    position: 'absolute',
                    width: '22px',
                    height: '22px',
                    borderRadius: '50%',
                    backgroundColor: '#1976d2',
                    color: 'white',
                    fontSize: '11px',
                    lineHeight: '21.9px',
                    textAlign: 'center',
                    top: 0,
                    left: '100%',
                    marginLeft: '-13px',
                    marginTop: '-8px',
                    transition: 'all 300ms',
                    opacity: count > 0 ? 1 : 0,
                  }}
                >
                  {count}
                </span>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>

      {measurement_id === 1 && <TableWater onDelete={() => { }} />}
      {measurement_id === 5 && <TableGas onDelete={() => { }} />}
    </main>
  )
}

export default Meters
