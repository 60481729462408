// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState, useMemo } from 'react'
import { extendTheme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import Feedback from '../components/LocalElements/Feedback/Feedback'

import HelloScreen from './Auth/HelloScreen'
import defaultLogo from '../../img/logo.svg'

import RoutesComponent from '../../routes/RouteComponent'
import PositionedSnackbar from '../components/LocalElements/Snackbar/Snackbar'
import ModalComponent from '../components/LocalElements/Modal/Modal'
import FileViewerComponent from '../components/LocalElements/Modal/FileViewer'
import ImageViewerComponent from '../components/LocalElements/Modal/ImageViewer'

import ErrorBoundary from '../../services/app/processes/error-boundary.service'

import { AppProvider } from '@toolpad/core/AppProvider'
import { DashboardLayout } from '@toolpad/core/DashboardLayout'

import useReactRouter from '../hooks/useReactRouter.js'

import AddBoxIcon from '@mui/icons-material/AddBox'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import StorageIcon from '@mui/icons-material/Storage'
import GasMeterIcon from '@mui/icons-material/GasMeter'
import WorkIcon from '@mui/icons-material/Work'
import StoreIcon from '@mui/icons-material/Store'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import HttpsIcon from '@mui/icons-material/Https'

import CustomNavbarActions from '../components/CustomNavbarActions.jsx'

const demoTheme = extendTheme({
  colorSchemes: { light: true, dark: false },
  colorSchemeSelector: 'class',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
})

const Home = () => {
  const router = useReactRouter()

  const [readyData, setReadyData] = useState(false)

  const userRole = useSelector(state => state.headerReducer.role)

  const NAVIGATION = [
    {
      kind: 'header',
      title: 'Основные разделы',
    },
    {
      segment: 'metriva/protocol',
      title: 'Новый протокол',
      icon: <AddBoxIcon />,
    },
    {
      segment: 'metriva/protocols',
      title: 'Протоколы',
      icon: <AssignmentIcon />,
    },
    {
      segment: 'metriva/journals',
      title: 'Журналы',
      icon: <ContentPasteIcon />,
    },
    {
      segment: 'metriva/users',
      title: 'Пользователи',
      icon: <AccountCircleIcon />,
      roles: ['super', 'admin', 'brigadier'],
    },
    {
      segment: 'metriva/meters',
      title: 'База приборов',
      icon: <StorageIcon />,
    },
    {
      segment: 'metriva/etalon',
      title: 'Средства поверки',
      icon: <GasMeterIcon />,
      roles: ['super', 'admin'],
    },
    {
      segment: 'metriva/cases',
      title: 'Комплекты СП',
      icon: <WorkIcon />,
    },
    {
      segment: 'metriva/companies',
      title: 'Компании',
      icon: <StoreIcon />,
      roles: ['super'],
    },
    {
      segment: 'metriva/company',
      title: 'Компания',
      icon: <StoreIcon />,
      roles: ['admin'],
    },
    {
      kind: 'divider',
    },
    {
      kind: 'header',
      title: 'Дополнительно',
    },
    {
      segment: 'metriva/settings',
      title: 'Настройки',
      icon: <SettingsIcon />,
      roles: ['super', 'admin'],
      children: [
        {
          segment: 'journals',
          title: 'Журналы',
          icon: <ContentPasteIcon />,
        },
        {
          segment: 'tarrifs',
          title: 'Тарифы',
          icon: <AccountBalanceWalletIcon />,
        },
        // ----------------------------------------------------------------
        // {
        //   segment: 'tarrifs',
        //   title: 'СП',
        //   icon: <GasMeterIcon />,
        // },
        // ----------------------------------------------------------------
      ],
    },
    {
      segment: 'metriva/edit_password',
      title: 'Сменить пароль',
      icon: <HttpsIcon />,
    },
  ]

  const filteredNavigation = useMemo(() => {
    return NAVIGATION.filter(item => {
      if (!item.roles) {
        return true
      }
      return item.roles.includes(userRole)
    })
  }, [userRole])

  useEffect(() => {
    const timer = setTimeout(() => setReadyData(true), 2200)
    return () => clearTimeout(timer)
  }, [])

  return (
    <React.Fragment>
      {readyData ? (
        <AppProvider
          navigation={filteredNavigation}
          router={router}
          theme={demoTheme}
          branding={{
            logo: (
              <div
                className='logo'
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '0px',
                }}
              >
                <img
                  alt={''}
                  src={defaultLogo}
                  style={{
                    width: '30px',
                  }}
                />
                <h1
                  style={{
                    color: 'rgb(0, 132, 226)',
                    display: 'block',
                    fontSize: '24px',
                    letterSpacing: '2px',
                    fontWeight: 800,
                    marginTop: '8px',
                  }}
                >
                  МЕТРИВА
                </h1>
              </div>
            ),
            title: '',
            homeUrl: 'metriva/protocol',
          }}
        >
          <Feedback />
          <DashboardLayout
            slots={{
              toolbarActions: CustomNavbarActions,
            }}
          >
            <ErrorBoundary key={new Date().getTime()}>
              <RoutesComponent />
            </ErrorBoundary>
          </DashboardLayout>
          <PositionedSnackbar />
          <ModalComponent />
          <FileViewerComponent />
          <ImageViewerComponent />
        </AppProvider>
      ) : (
        <HelloScreen />
      )}
    </React.Fragment>
  )
}

export default Home
