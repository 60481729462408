const LockIcon = () => {
  return (
    <svg width='71' height='71' viewBox='0 0 71 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.8757 23.3652C19.8757 14.9084 26.7313 8.05273 35.1881 8.05273C42.3202 8.05273 48.3184 12.9306 50.0184 19.5355C50.3195 20.7055 51.5121 21.4098 52.6821 21.1087C53.8521 20.8076 54.5564 19.615 54.2553 18.445C52.0699 9.9541 44.3647 3.67773 35.1881 3.67773C24.315 3.67773 15.5007 12.4921 15.5007 23.3652V29.3578C12.2493 29.6007 10.132 30.2137 8.5843 31.7614C6.02148 34.3242 6.02148 38.449 6.02148 46.6986C6.02148 54.9481 6.02148 59.0729 8.5843 61.6357C11.1471 64.1986 15.2719 64.1986 23.5215 64.1986H46.8548C55.1044 64.1986 59.2292 64.1986 61.792 61.6357C64.3548 59.0729 64.3548 54.9481 64.3548 46.6986C64.3548 38.449 64.3548 34.3242 61.792 31.7614C59.2292 29.1986 55.1044 29.1986 46.8548 29.1986H23.5215C22.2046 29.1986 20.9928 29.1986 19.8757 29.209V23.3652ZM23.5215 49.6152C25.1323 49.6152 26.4382 48.3094 26.4382 46.6986C26.4382 45.0877 25.1323 43.7819 23.5215 43.7819C21.9107 43.7819 20.6048 45.0877 20.6048 46.6986C20.6048 48.3094 21.9107 49.6152 23.5215 49.6152ZM35.1881 49.6152C36.799 49.6152 38.1048 48.3094 38.1048 46.6986C38.1048 45.0877 36.799 43.7819 35.1881 43.7819C33.5773 43.7819 32.2715 45.0877 32.2715 46.6986C32.2715 48.3094 33.5773 49.6152 35.1881 49.6152ZM49.7715 46.6986C49.7715 48.3094 48.4656 49.6152 46.8548 49.6152C45.244 49.6152 43.9381 48.3094 43.9381 46.6986C43.9381 45.0877 45.244 43.7819 46.8548 43.7819C48.4656 43.7819 49.7715 45.0877 49.7715 46.6986Z'
        fill='#0084E2'
      />
    </svg>
  )
}

export default LockIcon
