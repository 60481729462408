// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'

import Fade from '@mui/material/Fade'

import protocols from '../../img/menu/protocols.svg'
import magazines from '../../img/menu/magazines.svg'
import users from '../../img/menu/users.svg'
import meters from '../../img/menu/meters.svg'
import etalons from '../../img/menu/etalons.svg'
import cases from '../../img/menu/cases.svg'
import companies from '../../img/menu/companies.svg'

import { setIsToggle } from '../../store/slices/app/controlers/toggle'
import { refreshHeader } from '../../store/slices/app/controlers/updater'

import { resetState } from '../../store/slices/app/comps/modal'
import { resetJournalId } from '../../store/slices/app/views/journals'

const NavBarMobile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [value, setValue] = useState(0)

  const role = useSelector(state => state.headerReducer.role)

  const isToggle = useSelector(state => state.toggleReducer.isToggle)

  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const openMenu = Boolean(anchorElMenu)

  const handleCloseMenu = () => setAnchorElMenu(null)

  const handleSidebarToggle = event => {
    dispatch(setIsToggle(!isToggle))
    setAnchorElMenu(event.currentTarget)
  }

  useEffect(() => {
    dispatch(refreshHeader())
  }, [])

  const IconContainer = props => {
    const { children } = props

    return (
      <span
        style={{
          height: '24px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          position: 'relative',
          marginBottom: '4px',
        }}
      >
        {children}
      </span>
    )
  }

  return (
    <React.Fragment>
      <Paper sx={{ position: 'relative', bottom: 0, left: 0, right: 0, zIndex: 3 }} elevation={1}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
          sx={{
            transition: 'none',
            flexFlow: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            borderTop: '1px solid var(--GreyBlue, #EAEDF3)',
            padding: '8px 12px',
            position: 'relative',
            width: '100%',
            height: 'auto',

            '& .MuiBottomNavigationAction-root': {
              transition: 'none',
              padding: 0,

              '& .MuiBottomNavigationAction-label': {
                fontSize: '9px',
                color: '#4f5960',
              },
              '&.Mui-selected .MuiBottomNavigationAction-label': {
                fontSize: '9px',
                transition: 'none',
              },
            },
          }}
        >
          <BottomNavigationAction
            label={`Новый`}
            onClick={() => {
              dispatch(refreshHeader())
              navigate(`/metriva/protocol`)
              handleCloseMenu()
              dispatch(resetState())
              dispatch(resetJournalId())
            }}
            icon={
              <IconContainer>
                <img alt={''} src={magazines} style={{ height: '22px' }} />
              </IconContainer>
            }
          />
          <BottomNavigationAction
            label={`Протоколы`}
            onClick={() => {
              dispatch(refreshHeader())
              navigate(`/metriva/protocols`)
              handleCloseMenu()
              dispatch(resetState())
              dispatch(resetJournalId())
            }}
            icon={
              <IconContainer>
                <img alt={''} src={protocols} style={{ height: '22px' }} />
              </IconContainer>
            }
          />
          <BottomNavigationAction
            label={`Приборы`}
            onClick={() => {
              dispatch(refreshHeader())
              dispatch(resetState())
              dispatch(resetJournalId())
              navigate(`/metriva/meters`)
              handleCloseMenu()
            }}
            icon={
              <IconContainer>
                <img alt={''} src={meters} style={{ height: '24px' }} />
              </IconContainer>
            }
          />
          <BottomNavigationAction
            label={`Комплекты`}
            onClick={() => {
              dispatch(refreshHeader())
              navigate(`/metriva/cases`)
              handleCloseMenu()
              dispatch(resetState())
              dispatch(resetJournalId())
            }}
            icon={
              <IconContainer>
                <img alt={''} src={cases} style={{ height: '24px' }} />
              </IconContainer>
            }
          />
          <BottomNavigationAction
            label={`Журналы`}
            onClick={() => {
              dispatch(refreshHeader())
              navigate(`/metriva/journals`)
              handleCloseMenu()
              dispatch(resetState())
            }}
            icon={
              <IconContainer>
                <img alt={''} src={magazines} style={{ height: '22px' }} />
              </IconContainer>
            }
          />
        </BottomNavigation>
      </Paper>
      {/* <nav
        className='header'
        style={{
          height: '68px',
          paddingLeft: '24px',
          paddingRight: '24px',
          backgroundColor: '#f8f9fc',
          borderBottom: 'none',
          position: 'relative',
        }}
      >
        <Menu
          id='fade-menu'
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          TransitionComponent={Fade}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',
              mt: '-42px',
              ml: '-6px',
            },
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(refreshHeader())
              navigate(`/metriva/protocol`)
              handleCloseMenu()
              dispatch(resetState())
              dispatch(resetJournalId())
            }}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '15px',
              height: '50px',
              paddingRight: '60px',
              display: 'flex',
              flexFlow: 'row',
              marginBottom: '8px',
              gap: '12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '40px',
                height: '40px',
                backgroundColor: 'rgb(248, 249, 252)',
                borderRadius: '50%',
              }}
            >
              <img alt={''} src={magazines} style={{ height: '20px' }} />
            </div>
            <h5>Новый протокол</h5>
          </MenuItem>
        </Menu>

        <div className='toggleLogoContainer'>
          <ToggleButtonGroup
            size='small'
            style={{ zIndex: '1000', marginLeft: '-9px' }}
            aria-label='Small sizes'
            onClick={handleSidebarToggle}
          >
            <ToggleButton style={{ color: '#0084e2', border: 'none' }}>
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </nav> */}
    </React.Fragment>
  )
}

export default NavBarMobile
