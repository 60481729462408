import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

function useReactRouter() {
  const navigate = useNavigate()
  const location = useLocation()

  const router = React.useMemo(
    () => ({
      pathname: location.pathname,
      searchParams: new URLSearchParams(location.search),
      navigate: path => navigate(path),
    }),
    [location, navigate],
  )

  return router
}

export default useReactRouter
