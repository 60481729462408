// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import api from '../../services/app/client-server/axios-config.service'

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { IconButton } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import Fade from '@mui/material/Fade'
import StoreIcon from '@mui/icons-material/Store'

import protocols from '../../img/menu/protocols.svg'
import magazines from '../../img/menu/magazines.svg'
import users from '../../img/menu/users.svg'
import meters from '../../img/menu/meters.svg'
import etalons from '../../img/menu/etalons.svg'
import cases from '../../img/menu/cases.svg'
import companies from '../../img/menu/companies.svg'

import Logo from '../../img/metriva-logo.svg'
import refreshImg from '../../img/refresh.svg'
import defaultLogo from '../../img/logo.svg'

import { setIsToggle } from '../../store/slices/app/controlers/toggle'
import { refreshHeader } from '../../store/slices/app/controlers/updater'
import { setAuthToken } from '../../store/slices/app/controlers/auth'
import useWindowDimensions from '../hooks/useWindowDimensions'

import D from '../../store/localStorage/dispatcher'

import { resetState } from '../../store/slices/app/comps/modal'
import { resetJournalId } from '../../store/slices/app/views/journals'

const NavBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = useSelector(state => state.authReducer.authData.token)
  const loadingCount = useSelector(state => state.loadingReducer.loadingCount)

  // ----------------------------------------------------------------
  // подстраховка на случай если все сломается
  // const token = useSelector(state => state.authReducer.authData.token)
  // ----------------------------------------------------------------

  const refreshImgRef = useRef(null)
  const { width } = useWindowDimensions()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get('userRole')
  const userRole = useSelector(state => state.headerReducer.role)

  const role = useSelector(state => state.headerReducer.role)
  const surname = useSelector(state => state.headerReducer.surname)
  const name = useSelector(state => state.headerReducer.name)

  const pageTitle = useSelector(state => state.pageNameReducer.title)

  // ----------------------------------------------------------------
  // const logoPath = useSelector((state) => state.headerReducer.logo)
  // const role = D.get("userRole")
  // const surname = D.get("userSurName")
  // const name = D.get("userName")
  // ----------------------------------------------------------------

  const isToggle = useSelector(state => state.toggleReducer.isToggle)

  const companiesPing = useSelector(state => state.updaterReducer.companiesPing)
  const casesPing = useSelector(state => state.updaterReducer.casesPing)
  const ticketsPing = useSelector(state => state.updaterReducer.ticketsPing)
  const conditionsPing = useSelector(state => state.updaterReducer.conditionsPing)
  const personalMetrologPing = useSelector(state => state.updaterReducer.personalMetrologPing)
  const issueCasePing = useSelector(state => state.updaterReducer.issueCasePing)
  const receivedsPing = useSelector(state => state.updaterReducer.receivedsPing)
  const archivesPing = useSelector(state => state.updaterReducer.archivesPing)
  const entriesArchivePing = useSelector(state => state.updaterReducer.entriesArchivePing)
  const complectsPing = useSelector(state => state.updaterReducer.complectsPing)
  const etalonsPing = useSelector(state => state.updaterReducer.etalonsPing)
  const metersPing = useSelector(state => state.updaterReducer.metersPing)
  const usersPing = useSelector(state => state.updaterReducer.usersPing)
  const protocolsPing = useSelector(state => state.updaterReducer.protocolsPing)
  const headerPing = useSelector(state => state.updaterReducer.headerPing)
  const customUpdatePing = useSelector(state => state.updaterReducer.customUpdatePing)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const [anchorElMenu, setAnchorElMenu] = useState(null)

  const handleCloseMenu = () => setAnchorElMenu(null)

  const translateRole = useMemo(() => {
    let roleLocal = ''

    if (role === 'admin') {
      roleLocal = 'администратор'
    } else if (role === 'super') {
      roleLocal = 'суперадмин'
    } else if (role === 'metrologist') {
      roleLocal = 'поверитель'
    } else if (role === 'brigadier') {
      roleLocal = 'руководитель'
    }

    return roleLocal
  }, [role])

  const handleLogout = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/auth/logout`

      const axiosInstance = api.create({
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })

      const response = await axiosInstance.post(apiUrl)

      if (response.status === 200) {
        handleCloseMenu()

        Cookies.remove('accessToken')
        localStorage.clear()

        dispatch(setAuthToken(''))
      } else {
        console.error('Ошибка при выходе из системы')
      }
    } catch (error) {
      console.error('Произошла ошибка:', error)
    }
  }

  const handleEditPassword = () => {
    navigate(`edit_password`)
    handleCloseMenu()
  }

  useEffect(() => {
    width > 1050 && refreshImgRef.current.setAttribute('class', 'pop_up_exit_refresh_img')
    setTimeout(() => {
      width > 1050 && refreshImgRef.current.removeAttribute('class')
    }, 1400)
  }, [
    // ----------------------------------------------------------------
    // ----------------------------------------------------------------
    // companiesPing,
    // casesPing,
    // ticketsPing,
    // conditionsPing,
    // complectsPing,
    // etalonsPing,
    // metersPing,
    // usersPing,
    // protocolsPing,
    // personalMetrologPing,
    // entriesArchivePing,
    // issueCasePing,
    // receivedsPing,
    // archivesPing,
    // headerPing,
    // customUpdatePing,
    // ----------------------------------------------------------------
    // ----------------------------------------------------------------
    loadingCount,
  ])

  useEffect(() => {
    dispatch(refreshHeader())
  }, [])

  return (
    <React.Fragment>
      <nav className='header'>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.32))',
              mt: 1,
              ml: 1.05,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: 0,
                mr: 1.5,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 18,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            onClick={handleEditPassword}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '15px',
              height: '46px',
            }}
          >
            <svg
              width='28'
              height='28'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8ZM14 16C14 17.1046 13.1046 18 12 18C10.8954 18 10 17.1046 10 16C10 14.8954 10.8954 14 12 14C13.1046 14 14 14.8954 14 16Z'
                fill='#899298'
              />
            </svg>
            <span style={{ marginLeft: '10px' }}>Сменить пароль</span>
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            style={{
              fontFamily: '"Wix Madefor Display", sans-serif',
              fontSize: '15px',
              height: '46px',
            }}
          >
            <svg
              width='28'
              height='28'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447ZM16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H9.81066L11.5303 14.4697C11.8232 14.7626 11.8232 15.2374 11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303L7.46967 12.5303C7.32902 12.3897 7.25 12.1989 7.25 12C7.25 11.8011 7.32902 11.6103 7.46967 11.4697L10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967C11.8232 8.76256 11.8232 9.23744 11.5303 9.53033L9.81066 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12Z'
                fill='#899298'
              />
            </svg>
            <span style={{ marginLeft: '10px' }}>Выйти из системы</span>
          </MenuItem>
        </Menu>

        <div className='toggleLogoContainer'>
          <div
            className='logo'
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <img
              alt={''}
              src={defaultLogo}
              style={{
                width: '30px',
              }}
            />
            <h1
              style={{
                color: 'rgb(0, 132, 226)',
                display: 'block',
                fontSize: '24px',
                letterSpacing: '2px',
                fontWeight: 800,
                marginTop: '8px',
              }}
            >
              МЕТРИВА
            </h1>
          </div>
        </div>

        <div className='profile'>
          <div className='profile_user' style={{ gap: '8px' }}>
            <span>{translateRole}</span>
            <h3>{`${surname} ${name}`}</h3>
          </div>

          <div className='pop_up_exit_system'>
            <IconButton
              onClick={handleClick}
              size='small'
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              style={{
                width: '30px',
                height: '30px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '8px',
              }}
            >
              <MoreVertIcon sx={{ width: 22, height: 22 }} />
            </IconButton>
            <div
              className='pop_up_exit'
              style={{
                display: 'flex',
                backgroundColor: 'rgba(0, 132, 226, 1)',
                border: '1px solid rgba(0, 132, 226, 1)',
                marginLeft: '9px',
              }}
            >
              <img
                className='pop_up_exit_refresh_img'
                alt={''}
                src={refreshImg}
                ref={refreshImgRef}
              />
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default NavBar
