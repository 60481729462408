import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import theme from '../../../../../utils/theme.ts'
import { TotalAcceptedTrue, TotalAcceptedFalse } from '../../styles.ts'

import useWindowDimensions from '../../../../hooks/useWindowDimensions.js'
import { setName } from '../../../../../store/slices/app/controlers/pageName.js'

const TotalAccepted = props => {
  const { value } = props
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setName('Заключение поверки'))
  }, [dispatch])

  return (
    <React.Fragment>
      {value ? (
        <TotalAcceptedTrue style={width > 1050 ? {} : { padding: '12px' }}>
          <Typography
            variant='h2'
            color={theme.palette.text.secondary}
            style={width > 1050 ? {} : { marginBottom: '9px' }}
          >
            Заключение поверки
          </Typography>
          <Typography variant='subtitle2' color={theme.palette.text.secondary}>
            На основании результатов поверки, средство измерения признано пригодным к использованию.
          </Typography>
        </TotalAcceptedTrue>
      ) : (
        <TotalAcceptedFalse style={width > 1050 ? {} : { padding: '12px' }}>
          <Typography
            variant='h2'
            color={theme.palette.background.error}
            style={width > 1050 ? {} : { marginBottom: '9px' }}
          >
            Заключение поверки
          </Typography>
          <Typography variant='subtitle2' color={theme.palette.background.error}>
            На основании результатов поверки, средство измерения признано непригодным к
            использованию.
          </Typography>
          {width > 1050 && (
            <Typography
              variant='subtitle2'
              color={theme.palette.background.error}
              sx={{ opacity: '0.8' }}
            >
              * Статус заключения зависит от результатов внешнего осмотра, опробования, определения
              относительной погрешности.
            </Typography>
          )}
        </TotalAcceptedFalse>
      )}
    </React.Fragment>
  )
}

export default TotalAccepted
