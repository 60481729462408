const CoolIcon = () => {
  return (
    <svg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M60.6541 48.1487L62.7413 36.0794C63.2626 33.0649 60.9444 30.3078 57.8885 30.3078H42.5611C41.042 30.3078 39.8852 28.9442 40.1311 27.4434L42.0915 15.4783C42.41 13.5344 42.319 11.5454 41.8244 9.63884C41.4147 8.05943 40.1962 6.79122 38.5936 6.2764L38.1647 6.13862C37.1962 5.82748 36.1391 5.89989 35.2259 6.33991C34.2208 6.82424 33.4855 7.70767 33.213 8.75833L31.8057 14.1835C31.3579 15.9096 30.7057 17.5758 29.8647 19.1501C28.6359 21.4504 26.7362 23.2914 24.7614 24.9931L20.5051 28.6608C19.305 29.6949 18.6748 31.2436 18.8113 32.8229L21.214 60.6096C21.4344 63.1583 23.5653 65.1149 26.1206 65.1149H39.8728C50.1716 65.1149 58.9609 57.9392 60.6541 48.1487Z'
        fill='#0084E2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.4688 28.0919C10.6559 28.0407 11.6726 28.9337 11.775 30.1175L14.6493 63.3589C14.8339 65.4942 13.152 67.3345 11.0043 67.3345C8.98134 67.3345 7.3457 65.6934 7.3457 63.6743V30.3086C7.3457 29.1205 8.28172 28.1432 9.4688 28.0919Z'
        fill='#0084E2'
      />
    </svg>
  )
}

export default CoolIcon
